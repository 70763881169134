@import "./vars/variables";
@import "./vars/functions";
@import "./fonts";
@import "./input-range";
@import "./checkbox";
@import "./spinner";
@import "./pdf-pagination";
@import "./tabs";

@import "./auth/sign";

@import "./header/header";
@import "./footer/footer";
@import './header/notification';

@import "./sidebar/sidebar";

@import "vars/form/field";
@import "vars/form/btn";
@import "vars/form/search";
@import "vars/form/table";
@import "vars/modal/modal";
@import "vars/modal/popup";
@import "./content/form/checkbox";
@import "./content/form/dropdown";
@import "./content/form/toggle-block";
@import "./content/form/chart";
@import "./content/form/carousel";
@import "./content/form/editor";


@import "./content/booking/booking";
@import "./content/booking/calendar";
@import "./content/dashboard/dashboard";
@import "./content/dashboard/aside";
@import "./content/club/club";
@import "./content/computer/computer";
@import "./content/tariffs/tariffs";
@import "./content/person/person";
@import "./content/settings/settings";
@import "./content/reports/reports";
@import './content/logs/logs';

@import "./content/person/edit-person";
@import "./content/tariffs/tariffs-add";
@import "./content/users/users";
@import './content/shift/shift';
@import "./content/discounts/discounts";
@import "./content/map-center/center";
@import "./content/program/program";
@import "./content/program/program-page";
@import './content/tariffs/tariffs-edit';
@import "./content/achievements/achievements";
@import "./content/achievements/edit-achievements";
@import "./content/discipline/discipline";
@import "./content/news/news";
@import "./content/stocks/stocks";
@import "./content/tournaments/tournaments";
@import "./content/promo-code/promo";
@import "./content/tournament/tournament";
@import './content/questions/questions';
@import './content/questions/messanger';
@import './content/computer/seasons';
@import './content/collector/collector';
@import './content/guest/guest';
@import './content/qr/qr';

@import "footer/menu";

//Helpers
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.full-height {
    height: 100%;
}

.no-padding {
    padding: 0 !important;
}

* {
    margin: 0;
    padding: 0;
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
    overflow: hidden !important;
}

 .container-fluid {
	 padding-left: rem(15);
	 padding-right: rem(15);
 }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-left: rem(15);
    padding-right: rem(15);
}

.row {
    margin-left: rem(-15);
    margin-right: rem(-15);

    &.no-gutters {
        margin-left: 0;
        margin-right: 0;

        & > .col,
        & > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.no-parent {
        & > .col,
        & > [class*="col-"] {
            position: static;
        }
    }
}
.small-gutters {
    .row {
        margin-left: rem(-7.5);
        margin-right: rem(-7.5);
        .col,
        .col-1,
        .col-10,
        .col-11,
        .col-12,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-auto,
        .col-lg,
        .col-lg-1,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-auto,
        .col-md,
        .col-md-1,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-auto,
        .col-sm,
        .col-sm-1,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-auto,
        .col-xl,
        .col-xl-1,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-auto {
            padding-left: rem(7.5);
            padding-right: rem(7.5);
        }
    }
}
.row,
.d-flex {
    .pull-left {
        margin-right: auto;
    }

    .pull-right {
        margin-left: auto;
    }
}

.is-sticky {
    position: fixed;
    top: 10px;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

body > #root > div {
    height: 100vh;
}
html {
    font-size: #{$browser-context}px;
}

body {
	background: #1e1f24;
    font-family: $skwgf;
    color: #000;
    overflow-y: scroll;

    &.modal-show,
    &.menu-show {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    margin-bottom: 0;
}

// h1{

// }

h2 {
    font-size: rem(44);
}

h3 {
    font-size: rem(32);
    font-weight: 600;
}

h4 {
    font-size: rem(24);

    span {
        font-weight: 500;
    }
}

h5 {
    font-size: rem(18);
}

h6 {
    font-size: rem(10);
}

a {
    color: $link-color;
    text-decoration: none !important;
    @include transition();

    &:hover,
    &:focus {
        color: $secondary-color;
        text-decoration: none !important;
    }
}
.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	color: $white;
    padding: rem(10) rem(20);
	//background-color: #1E1F24;
}

p {
    line-height: rem(26);
	margin-bottom: 0;
}
.logo {
    display: flex;
    max-width: rem(258);
}
.lvb-logo {
    display: flex;
    a {
        display: flex;
        color: #000;
    }
    .logo-txt {
        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: left;
        padding-left: rem(10);
        h5 {
            margin-bottom: rem(5);
            font: {
                family: "Roboto Bold";
                size: rem(12);
            }
        }
        h6 {
            font: {
                family: "Roboto";
                size: rem(8);
            }
        }
    }
}

.error-message {
    color: $red;
    display: block;
    margin-top: rem(5);
}

.admin-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	background: #1E1F24;

	&-container {
		height: 100%;
	}

	&-row {
		height: 100%;
	}

    .admin-footer {
        padding-top: rem(15);
        padding-bottom: rem(15);
        .cm-col {
            p {
                margin-bottom: 0;
            }
            display: flex;
            align-items: center;
            &.right {
                justify-content: flex-end;
            }
        }
    }

	&__non-aside {
		background: #1E1F24;

		.content {
			//height: calc( 100vh - 12.125rem - 4.56rem - 1.35rem );
			//padding-right: 0;
            //height: 100%;
		}
	}
}
.content {
	background: #1E1F24;
    flex: 1;
    height: 100%;
	//overflow: hidden;
	//padding-right: rem(30);
	//padding-bottom: rem(30);

    .top-heading {
        margin-bottom: rem(30);
    }
}

.no-document {
    text-align: center;
    img {
        margin-bottom: rem(20);
    }
    h2 {
        color: $heading-green;
        font: {
            family: "Roboto Bold";
            size: rem(20);
        }
        margin-bottom: rem(5);
    }
    p {
        font: {
            family: "Roboto";
            size: rem(12);
        }
        margin-bottom: rem(20);
    }
}

.divider {
	background-color: rgba(255, 255, 255, 0.10);
    height: rem(1);
    width: 100%;
    margin-top: rem(30);
    margin-bottom: rem(30);
}

.accordion {
    width: 100%;
    .card {
        margin-bottom: rem(10);
        border: 0;
        border-radius: rem(4);
        .card-header {
            background: $white;
            cursor: pointer;
            border-bottom: 0;
            padding: 0;
            [data-toggle="collapse"] {
                padding: rem(12) rem(24);
            }
        }
        .card-body {
            padding: rem(12) rem(24);
            padding-top: 0;
            .row {
                margin-bottom: rem(15);
                .item {
                    .sub-heading {
                        font: {
                            family: "PT Sans";
                            size: rem(10);
                        }
                        margin-bottom: rem(5);
                        &.big {
                            margin-bottom: rem(10);
                            font: {
                                family: "Open Sans";
                                size: rem(14);
                            }
                        }
                    }
                    .heading {
                        font: {
                            family: "PT Sans";
                            size: rem(14);
                        }
                    }
                    .btn {
                        font: {
                            family: "Open Sans SemiBold";
                            size: rem(14);
                        }
                    }
                }
            }
        }
    }
    .col {
        font-size: rem(14);
    }
    .id {
        font: {
            family: "PT Sans Bold";
        }
    }
    &.status {
        span {
            color: $black;
        }
        .not-completed {
            color: $red;
        }
        .completed {
            color: $primary-color;
        }
    }
    .arrow {
        svg {
            path {
                fill: rgba($color: $black, $alpha: 0.3);
            }
            @include transition();
            &.active {
                transform: rotate(180deg);
                path {
                    // fill: $primary-color;
                    fill: rgba($color: $black, $alpha: 1);
                }
            }
        }
    }
}
.information {
    // display: flex;
    &.list-wrapper {
        .info-top-header {
            .btn {
                padding-left: rem(40);
                padding-right: rem(40);
            }
        }
    }

    .info-top-header {
        margin-bottom: rem(30);
        display: flex;
        align-items: center;
        .btn {
            padding-left: rem(20);
            padding-right: rem(20);
            border-radius: rem(4);
            padding-top: rem(9);
            padding-bottom: rem(9);
            font: {
                family: "PT Sans Bold";
                size: rem(14);
            }
        }
        h3 {
            font: {
                family: "PT Sans";
                size: rem(24);
            }
        }
    }
}

.case-item {
    border-radius: rem(4);
    padding: rem(15);
    border: rem(2) solid $gray;
    height: 100%;
    cursor: pointer;
    h5 {
        font: {
            family: "PT Sans Bold";
            size: rem(16);
        }
        margin-bottom: rem(5);
    }
    p {
        font: {
            family: "PT Sans";
            size: rem(10);
        }
        line-height: rem(18);
        margin-bottom: 0;
    }
    @include transition();
    &.active {
        background-color: rgba(#13ac97, 0.05);
        border-color: transparent;
    }
}

.custom-card {
    border: none;
    padding: rem(30) rem(20);
    border-radius: rem(4);
    margin-bottom: rem(20);
    .card-body {
        padding: 0;
    }
    &-small {
        position: sticky;
        top: rem(10);
        border: none;
        padding: rem(20);
        border-radius: rem(4);
        margin-bottom: rem(20);
        .btn {
            min-height: rem(47);
            margin-bottom: rem(15);
            width: 100%;
            font-size: rem(18);
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.pre-form {
    .form-group {
        margin-bottom: rem(20);
        &:last-child {
            margin-bottom: 0;
        }
    }
    h4,
    label {
        margin-bottom: rem(20);
    }
    h4 {
        font: {
            family: "PT Sans";
            size: rem(20);
        }
    }
    label {
        display: block;
        font: {
            family: "PT Sans";
            size: rem(14);
        }
    }
}
.info-wrapper {
    .info-block {
        span {
            display: block;
            font: {
                family: "PT Sans";
                size: rem(14);
            }
            margin-bottom: rem(10);
        }
        h4 {
            margin-bottom: rem(15);
            font: {
                family: "PT Sans Bold";
                size: rem(16);
            }
            // margin-bottom: 0;
        }
        .pre-price {
            font: {
                family: "Open Sans";
                size: rem(36);
            }
            color: $black;
            margin-bottom: rem(15);
        }
    }
}
.create-form {
    h5 {
        font: {
            family: "Roboto Bold";
            size: rem(14);
        }
        margin-bottom: rem(15);
    }
    h4 {
        font: {
            family: "Roboto Bold";
            size: rem(20);
        }
    }
    .row {
        margin-bottom: rem(30);
    }
    .form-control {
        height: rem(50);
    }
    .form-group {
        .btn-primary {
            width: auto;
            padding-left: rem(26);
            padding-right: rem(26);
        }
    }
}
.custom-modal {
    width: rem(530);
    border: none;
    padding: rem(20);
    border-radius: rem(4);
    background-color: $white;
    &:focus,
    &:hover {
        outline: none;
    }
    label {
        font: {
            family: "PT Sans";
            size: rem(14);
        }
        margin-bottom: rem(5);
    }
    h5 {
        font: {
            family: "PT Sans Bold";
            size: rem(16);
        }
    }
    a {
        display: block;
        color: $secondary-color;
        word-wrap: break-word;
        font: {
            family: "PT Sans";
            size: rem(18);
        }
    }
    .pre-price {
        font: {
            family: "PT Sans Bold";
            size: rem(32);
        }
    }
    a.btn {
        color: $white;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(48);
        padding-top: 0;
        padding-bottom: 0;
        &.btn-primary-transparent {
            width: 100%;
        }
        &.btn-primary {
            width: 100%;
        }
    }
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-page {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        margin: rem(15) 0;
    }
}
.filter-select {
	&-type {
		padding: rem(2) rem(8);
		border-radius: rem(4);
		margin-left: auto;
		display: flex;
	}
}

.order-filters {
    input,
    button {
        height: rem(44);
    }
    input {
        border: none;
    }

    .input-group {
        .input-group-text {
            background-color: $white;
            border: none;
            padding-right: 0;
        }
        .form-control {
            border-left: none;
        }
    }
    .react-datepicker-wrapper {
        // input{
        //     font-size: rem(13);
        // }
        // .react-datepicker__close-icon{
        //     right:rem(-30);
        //     z-index: 200;
        // }
    }
}

.pagination {
    justify-content: center;
    .page-item {
        .page-link {
            border-color: $primary-color;
            color: $primary-color;
        }
        &.active {
            .page-link {
                color: $white;
				background: $primary-color;
            }
        }
    }
}

.collapse {
    .item {
        .btn {
            height: rem(45);
            display: flex;
            align-items: center;
        }
    }
}
.fixed-sidebar {
    position: fixed;
    left: 0;
    top: 0;
}

.today-orders {
    .card {
        height: 100%;
        border: none;
        border-radius: rem(8);
        .card-body {
            padding: rem(30);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .card-top {
                h4 {
                    font: {
                        family: "Open Sans SemiBold";
                        size: rem(20);
                    }
                    margin-bottom: rem(15);
                }
                p {
                    font: {
                        family: "Open Sans";
                        size: rem(14);
                    }
                    line-height: rem(24);
                    margin-bottom: rem(30);
                }
            }
            .card-bottom {
                button {
                    max-width: rem(255);
                    width: 100%;
                }
            }
        }
    }
    .orders {
        margin-top: rem(60);
        h4 {
            font: {
                family: "PT Sans Bold";
                size: rem(20);
            }
            margin-bottom: rem(30);
        }
    }
}

.header__map-dropdown div .react-select-prefix__control--menu-is-open {
    border-radius: rem(20) rem(20) rem(0) rem(0);
}

.react-select-prefix__control {
    height: rem(44);
}
.create-form {
    .react-select-prefix__control {
        height: rem(50);
    }
}
.react-pdf__Page__annotations.annotationLayer {
    display: none;
}
.react-pdf__Document {
    margin-bottom: rem(24);
}
.rc-input-number-input {
    text-align: center;
}

.ReactModal__Overlay {
	opacity: 0;
	//transform: translateX(-100px);
	transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	//transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
	//transform: translateX(-100px);
}

.line {
	height: rem(1);
	background: rgba(255, 255, 255, 0.10);
	margin-top: rem(15);
	width: 100%;
}

::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 2px;
	background: rgba(255, 255, 255, 0.10);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(53, 130, 246, 0.50);
	border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
	-moz-appearance:textfield;
}



@import "responsive";
