.achievements__edit {

    .edit__block__item__header {

        h2 {
            font-size: rem(20);
            line-height: rem(28);
            color: #FFFFFF;
        }
    }

    .tournament__edit__inner__right {
        margin-bottom: rem(20);
        h2 {
            color: $white;
            line-height: rem(28);
            font-size: rem(18);
        }
    }

    .discipline__content__form__right__file {
        gap: 0;

        &__field {
            display: flex;
            flex-direction: column;
            gap: rem(30);
        }
    }

    .form-file {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(20);
        min-height: rem(205);
        border-radius: rem(4);
        background: rgba(255, 255, 255, 0.05);
    }

    &-margin30px {
        margin-bottom: rem(40);
    }

    &-header {
        .btn {
            color: #3582F6;
        }


    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: rem(10);
    }

    &-title {
        color: #FFFFFF;
        margin-bottom: rem(10);
        font-size: rem(14);
    }

    &-comment {

        input {
            top: -50%;
            position: relative;
            padding: rem(30) rem(10) rem(100) rem(10);

            &::placeholder{
                top: 17%;
                left: 2%;
                position: absolute;

            }
        }
    }

    &-button {
        margin-top: rem(20);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .btn {
            width: 100%;
            justify-content: center;
        }
    }

    &-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;
    }

    &-icons {
        display: flex;
        align-items: center;
        gap: rem(5);
    }
}