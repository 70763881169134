
.booking {
	padding: rem(30);
	overflow: hidden;
	//height: calc(100vh - 4.5625rem);
	height: 100%;

	&__block {
		display: flex;
		align-items: center;
	}

	&__mobile-meta {
		display: none;
	}

	&__blocks-btn {
		padding-bottom: rem(15);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__datepicker {

		input {
			min-height: rem(50);
			width: 100%;
			border: 0;
		}
	}

	&__header {
		h1 {
			font-size: rem(20);
			color: $white;
			line-height: rem(28);
		}
		display: none;
	}

	&__type {
		margin-left: rem(15);

		.react-select-prefix {
			&__control {
				max-width: rem(276);
				min-width: rem(276);
			}
		}
	}

	&__blocks-content {
		width: 100%;
		height: 100%;
		padding: rem(16) 0;

		.popup__container {
			z-index: 5;
		}

		.fc-col-header-cell-cushion {
			font-size: 10px;
			font-weight: 600;
		}

		.fc-timelineDay-view {
			padding-left: 15px;
		}

		.fc-v-event .fc-event-main-frame {
			display: none;
		}

		.fc-event {
			border-radius: 8px;
			cursor: pointer;
			padding: 0;
			border: none;
			margin: 1.5px 3px !important;
			height: 135px;
		}

		.fc-timeline-event {
			margin: 5px !important;

			.fc-event-main {
				height: 135px;
			}
		}

		.fc-direction-ltr .fc-timegrid-col-events {
			margin: 0;
		}

		.fc-timegrid-col-frame {
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-bottom: 0;
		}

		.fc-day-mon .fc-timegrid-col-frame {
			border-left: 0;
		}

		.fc-day-sat .fc-timegrid-col-frame {
			border-right: 0;
		}

		.fc-day-sun .fc-timegrid-col-frame {
			background-color: rgba(0, 0, 0, 0.03);
			border-left: 0;
			border-right: 0;
		}

		.fc-timeline-header {
			margin-bottom: 10px;
		}

		.fc-timeline-slot {
			border: 0 !important;

			+ th div {
				margin-left: -17px;
			}
		}

		.fc-timeline-slot-cushion {
			color: $white;
			font-size: 12px;
			font-weight: 600;
			padding: 10px !important;
			padding-left: 0 !important;
		}

		table {
			border: 0 !important;
		}

		.fc-event-past {
			opacity: 0.5;
		}

		td, & th {
			border: 0 !important;

			.fc-scrollgrid-sync-inner {
				text-align: left;
			}
		}

		.fc-timeline-now-indicator-arrow {
			border-color: transparent;
		}

		.fc-timeline-now-indicator-line {
			border-image: linear-gradient(180deg, #FFC700 100%, rgba(255, 199, 0, 0.00) 100%) 1;

			&:after {
				content: '';
				position: absolute;

			}
		}

		.fc-timeline-slot-label {
			position: relative;

			 //&:before {
			 //  content: "";
			 //  position: absolute;
			 //  top: 50%;
			 //  transform: translateY(-50%);
			 //  right: calc(50% - 2px);
			 //  width: 4px;
			 //  height: 4px;
			 //  background-color: rgba(0, 0, 0, 0.3);
			 //  border-radius: 50%;
			 //}

			.customTimeLine {
				span {
					display: none;
				}
			}

			&:last-child {
				.customTimeLine {
					span {
						position: absolute;
						display: block;
						top: 0;
						right: 4px;
						padding: 10px 0;
					}
				}
			}
		}

		.fc-timeline-slots .fc-slot-future {
			position: relative;
			border-radius: 8px;
			cursor: pointer;

			&, &:after {
				transition: all .3s ease;
			}

			&:after {
				content: "+";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				color: rgba(0, 123, 255, 1);
				font-size: 35px;
				opacity: 0;
			}

			&:hover {
				background: rgba(0, 123, 255, 0.05);

				&:after {
					opacity: 1;
				}
			}
		}

		.fc-timeGridWeek-view {
			.fc-scrollgrid-section-body .fc-timegrid-axis {
				opacity: 0;
				visibility: hidden;
			}

			.fc-col-header {
				margin-bottom: 15px;

				.fc-day-today {
					color: #0071DA;
				}

				.fc-col-header-cell-cushion {
					text-transform: capitalize;
				}
			}

			.fc-event-time {
				font-size: 12px;
				font-weight: 400;
				opacity: .5;
			}

			.fc-event-title {
				font-weight: 600;
				font-size: 12px;
				line-height: 15px;
			}

			.fc-event {
				height: auto;

				.fc-event-main {
					padding: 0;
				}
			}

			.fc-timegrid-body {
				.fc-timegrid-slot {
					font-size: 10px;
					font-weight: 500;
					height: 30px;
					margin-bottom: 30px;
				}

				tr:nth-last-child(2) {
					.fc-timegrid-slot {
						.customTimeLine {
							span {
								position: absolute;
								display: block;
								bottom: -40px;
								right: 0;
								padding: 0 4px;
							}
						}
					}
				}
			}

			.fc-timegrid-col.fc-day-today {
				background: transparent;
			}
		}

		.fc-highlight {
			background-color: transparent;
		}

		.fc-timegrid-slot {
			position: relative;
			border-radius: 8px;
			cursor: pointer;

			&, &:after {
				transition: all .3s ease;
			}

			&:after {
				content: "+";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				color: rgba(0, 123, 255, 1);
				font-size: 32px;
				opacity: 0;
			}

			&:hover {
				background: rgba(0, 123, 255, 0.05);

				&:after {
					opacity: 1;
				}
			}

			.customTimeLine {
				span {
					display: none;
				}
			}
		}
	}

	&__popup {
		padding: rem(15) rem(20);
		gap: rem(20);
		min-width: rem(324);

		.line {
			margin-top: 0;
		}

		&__header {
			.title {
				display: flex;
				align-items: center;
				gap: rem(10);
				font-size: rem(16);
				line-height: rem(28);

				span {
					width: rem(12);
					height: rem(12);
					background-color: #FF5858;
					border-radius: 50%;
				}
			}
		}

		&__content {
			display: flex;
			//align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-top: rem(10);

			&-block {
				font-size: rem(14);
				line-height: rem(24);
				color: $white;
				min-width: 50%;
				padding-bottom: rem(20);
				padding-right: rem(5);

				&.title {
					opacity: 0.5;
				}
			}
		}
	}

	&__modal {
		//overflow: visible;

		.search__block-dialog {
			top: auto;
			bottom: calc(100% + 15px);
			max-height: rem(300);
			padding-bottom: 0;
			overflow: auto;
			box-shadow: 0 -4px 16px 10px #292A2F;
		}
	}
}

.added__booking__dialog {
	&-setting {
		h4 {
			color: $white;
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(22);
			margin-bottom: rem(15);
		}

		&-date {
			display: flex;
			align-items: center;
			padding-bottom: rem(15);

			.custom-calendar {
				width: 100%;
			}

			&-time {
				margin-left: rem(15);
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				max-width: rem(214);
				width: 100%;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: rem(5);
					height: rem(2);
					background-color: rgba(255, 255, 255, 0.1);
					border: 100px;
				}

				&.is-select-tariff {
					&:before {
						content: none;
					}
				}
			}
		}

		&-zone {
			.dropdown__block  {
				margin-bottom: rem(15);

				.search__block__clear {
					right: rem(30);

					.btn {
						margin-bottom: 0;
					}
				}
			}

			.btn {
				margin: 0 0 rem(15);
			}
		}
	}

	&-save {
		display: flex;
		padding-top: rem(15);

		.btn {
			text-align: center;
			justify-content: center;
		}
	}

	&-cancel {
		display: flex;
		padding-top: rem(15);

		.btn {
			text-align: center;
			justify-content: center;
			border: 0;
			max-height: rem(46);
		}
	}

	&-price {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(30) 0 rem(15);
		color: #70D584;
	}

	&__disabled-fields {
		input, .react-select-prefix__control, .added__booking__dialog-added-btn {
			pointer-events: none;
		}
	}
}

div .react-datepicker-popper {
	z-index: 5;
}
