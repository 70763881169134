.club {
    &s {
        padding: rem(30) rem(15);
        color: $white;
    }

    &__header {
        padding-bottom: rem(15);

        .btn-icon {
            color: #3582F6;
            font-size: rem(14);
            line-height: rem(22);
        }

        h4 {
            font-size: rem(20);
            line-height: rem(28);
        }

		&-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: rem(30);

			.btn {
				max-height: rem(45);
				margin-left: rem(15);
			}

			.search__block__clear {
				margin-right: rem(20);
			}
		}
    }

    &__content .btn-add-club, &__help {
        border-radius: rem(4);
        min-height: rem(318);
        width: 100%;
        padding: rem(15);
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: rem(30);

        .btn-add-club {
            color: #3582F6;
            border: rem(1) dashed #3582F6;
            background: linear-gradient(225deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem(15);
            max-width: rem(298);
        }
    }

    &__block {
        background-color: rgba(255, 255, 255, 0.05);
        margin-top: rem(15);
        max-width: rem(298);
        width: 100%;
        min-height: rem(318);
        padding: rem(15);
        display: flex;
        flex-direction: column;
        border-radius: rem(4);

        &.active {
			border: 1px solid rgba(255, 255, 255, 0.15);
			background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100.93%);

			.btn {
				background: transparent;
			}
        }

        &-header {
            padding-bottom: rem(20);

            &-title {
            }

            &-state {
                opacity: 0.5;
            }
        }

        &-type {
            span {
                color: #70d584;
                font-size: rem(12);
                border-radius: rem(4);
                display: inline-block;
                padding: rem(2) rem(8);
                background: rgba(112, 213, 132, 0.10);
                line-height: rem(20);
            }
        }

        &__btn {
            margin-top: auto;
            display: flex;
            flex-direction: row;
            gap: rem(15);
            justify-content: center;


            .btn {
                margin: 0;
                border-radius: 0.25rem;
                padding: 0.8125rem 1.25rem;
                border: rem(1) solid #007bff;
                font-size: 1rem;
                //max-width: rem(126);
                background-color: transparent;
                overflow: hidden;
            }

            .btn:focus, .btn:active {
                background: linear-gradient(225deg, #3582F6 0%, #A414FF 100%);
                box-shadow: none !important;
                border: rem(1) solid #FFFFFF;
            }

            .btn:hover {
                color: #FFFFFF;
            }


        }

    }

    &__help {
        background: linear-gradient(225deg, #3582F6 0%, #A414FF 100%);
        display: flex;
        flex-direction: column;
        max-width: rem(235);

        h3 {
            font-size: rem(20);
            line-height: rem(28);
            margin-bottom: rem(30);
        }

        p {
            font-size: rem(14);
            line-height: rem(22);
        }
    }

    &__add {
        &__header {
            padding-bottom: rem(30);
        }

        &__content {
            p, a {
                font-size: rem(14);
                line-height: rem(22);
                margin-bottom: rem(14);
            }

            form {
				display: flex;
				flex-wrap: wrap;

                .form-group{
					width: 100%;
                    margin-bottom: rem(10);
                }

				.title, .toggle {
					width: 100%;
				}

				.w-50 + .w-50 {
					width: calc(50% - 20px) !important;
					margin-left: rem(15);
				}
            }

            &-btn {
                padding-top: rem(20);
				display: flex;
				align-items: center;

				.btn {
					max-height: rem(46);
				}

				&-mobile {
					display: none;
				}
            }

            &-title {
                display: flex;
                justify-content: space-between;

                a {
                    color: #3582F6;
                }

                p, a {
                    margin-bottom: rem(12);
                }
            }

            &-type {
                border-radius: rem(4);
                background: rgba(255, 255, 255, 0.05);

                &__name {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: rem(1) solid rgba(255, 255, 255, 0.1);
                    padding: rem(20);

                    p {
                        margin-bottom: 0;
                    }

                    span {

                        display: block;
                        font-size: rem(14);
                        line-height: rem(18);
                        padding: rem(2) rem(23.5);
                        border-radius: rem(4);


                        &.active {
                            background-color: #70D58420;
                            color: #70D584;
                        }
                    }
                }

                &__item {
                    padding: rem(10) rem(20);
                    width: 50%;

                    &s {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    span, p {
                        font-size: rem(14);
                        line-height: rem(22);
                        margin-bottom: 0;
                    }

                    p {
                        opacity: 0.5;
                    }

                    span {
                        display: block;
                    }
                }

                &-date {
                    border-left: rem(1) solid rgba(255, 255, 255, 0.05);
                }
            }

            &-block {
                margin-bottom: rem(30);
            }

            .btn-link {
                border-color: #3582F6;
            }

            &-stock {
                border-radius: rem(4);
                background: linear-gradient(225deg, #3582F6 0%, #A414FF 100%);
                padding: rem(20);

                h4 {
                    font-size: rem(20);
                    line-height: rem(28);
                    margin-bottom: rem(4);
                }

                p {
                    font-size: rem(14);
                    line-height: rem(18);
                    margin-bottom: 0;
                }
            }

            &-cloud {
                margin-top: rem(30);

                &__header, &__footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__header {
                    padding-bottom: rem(12);

                    h4 {
                        font-size: rem(20);
                        line-height: rem(28);
                    }

                    svg {
                        font-size: rem(16);
                    }
                }

                &__content {
                    border-radius: rem(4);
                    background: rgba(255, 255, 255, 0.05);
                    padding: rem(20);

                    p {
                        font-size: rem(14);
                        line-height: rem(18);
                        margin-bottom: 0;
                        color: rgba(255, 255, 255, 0.50);
                    }
                }

                &__footer {
                    margin-top: rem(30);

                    .btn {
                        width: 100%;
                        justify-content: center;

                        & + & {
                            margin-left: rem(30);
                        }
                    }
                }
            }

			.toggle {
				margin-bottom: rem(10);

				p {
					margin-bottom: 0;
				}
			}
        }

        &__right {
            padding-top: rem(22);
        }
    }
}
