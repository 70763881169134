
.program__block {
    display: flex;
    height: 100%;

    &__item {
        height: 100%;

        &__header {
            padding: rem(30) rem(30);
            display: flex;
            align-items: center;
            gap: rem(31);

            .btn {
                max-width: rem(193);
                width: 100%;
                min-height: rem(47);
                border-color: #3582F6;
                box-shadow: none;

                &-link {
                    color: #3582F6;
                }

                &-link:active,
                &-link:focus {
                    background: rgba(53, 130, 246, 0.20);
                }
            }

            &-last {
                margin-left: auto;
                max-width: rem(193);
                width: 100%;
                min-height: rem(47);
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: rem(0) rem(15);
            &__image {
                padding: rem(15);
                &__btn {
                    display: flex;
                    flex-direction: column;
                    gap: rem(10);

                    img {
                        max-width: rem(210);
                        width: 100%;
                        min-height: rem(315);
                        object-fit: cover;
                        border-radius: rem(8);
                        cursor: pointer;
                    }
                }

                span {
                    color: $white;
                }
            }
        }

        &__appellation {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: hsla(0,0%,100%,0.5);
                text-align: center;
            }
        }
    }
}
