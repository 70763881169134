.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(255, 255, 255, 0.10);
	background-color: #222329;
	padding: rem(11) rem(30);
	&__left, &__right, &__profile, &__other, &__map {
		display: flex;
		align-items: center;
	}

	&__logo {
		margin-right: rem(30);
	}

	&__profile {
		position: relative;
		align-items: center;
		cursor: pointer;

		&-name {
			padding-left: rem(12);
			padding-right: rem(10);
			font-size: rem(14);
			color: #ffffff;
			margin-bottom: 0;
		}

		&-type {
			padding: 0 rem(6);
			background-color: #70D584;
			border-radius: rem(4);

			p {
				font-size: rem(12);
				line-height: rem(18);
				margin-bottom: 0;
			}
		}

		&-logo {
			img {
				max-width: rem(44);
				border-radius: 50%;
			}
		}
	}

	&__separator {
		margin: 0 rem(24);
		height: rem(44);
		width: rem(1);
		background-color: #D9D9D9;
		opacity: 0.1;
	}

	&__aside {
		color: #FFFFFF;
		border-radius: rem(4);
		top: 100%;
		z-index: 100;
		position: absolute;
		width: rem(253);
		background-color: #222329;
		padding: rem(10);
		border: rem(1) solid rgba(255, 255, 255, 0.05);
		margin-top: rem(21);

		&-accounts {
			padding-bottom: 15px;
			display: flex;
			gap: 10px;
			border-bottom: 1px solid hsla(0,0%,100%,0.2);
		}

		&-numbers {
			display: flex;
			flex-direction: column;
			padding: 10px 0 10px 48px;
			border-bottom: 1px solid hsla(0,0%,100%,0.2);
		}

		&-ids {
			margin-top: 20px;
		}

		&-buttons {
			padding-top: rem(10);
			display: flex;
			flex-direction: column;
		}

		&-button {
			display: flex;
			align-items: center;
			background-color: #222329;
			border: none;
			color: #FFFFFF;
			padding: rem(7) rem(10);
			border-radius: rem(4);

			&:hover {
				background-color: #3a3a46;
			}

			&.green {
				color: #70D584;
			}

			&.red {
				color: #FF5858;
			}
		}

		&-toggle {
			justify-content: space-between;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 10px 0 ;
			border-bottom: 1px solid hsla(0,0%,100%,0.2);

			div {
				justify-content: space-between;
				background-color: #222329;
				gap: 35px;
			}
		}

		&-paragraph {
			color: grey;

			&_active {
				color: #2c87fd;
				line-height: 16px;
				padding: 1px;
			}
		}

		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px ;
			border-radius: 4px;
			background-color: rgba(255, 255, 255, 0.1);
		}

		&-btn {
			margin-top: 4px;
			width: fit-content;
			line-height: 18px;
			padding: 3px ;
			background-color: rgba(44,135,253,0.2);
			border-radius: 2px;
			color: #2c87fd;
		}

		&-username {
			display: flex;
			align-items: center;
			font-size: 14px;

			p {
				line-height: 18px;
			}
		}

		&-bottom {
			padding: 15px 0 0 0 ;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

