.tariffs {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding: rem(30) rem(0);
    color: white;
	overflow: hidden;

    &__header {
        padding: 0 rem(15);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        &-checkboxes {
            display: flex;
            align-items: center;
            gap: rem(20);
        }

        &-select {
            width: rem(200);

        }

        &-btn {
            display: flex;
            justify-content: center;
            width: rem(210);
        }
    }

    &__content {
        flex-wrap: wrap;
        display: flex;
		padding: 0 rem(15);

		.row {
			width: 100%;
		}
    }

    &__block {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: rem(20);
        background: rgba(255, 255, 255, 0.05);
        border-radius: rem(8);
		height: 100%;

		&s {
			padding-top: rem(30);
			height: 100%;
		}

        &-title {
            color: #FFFFFF;
            opacity: 0.75;
            font-size: rem(14);
            font-weight: 400;
            line-height: rem(22);
            margin-bottom: rem(6);
        }

        &-header {
            display: flex;
            align-items: center;
            gap: rem(9);
            margin-bottom: rem(3);
        }

        &-btn {
            height: rem(35);
            display: flex;
            align-items: center;
            gap: rem(5);
            padding: rem(0) rem(8);
            border-radius: rem(4);
            color: #9D00FF;
            border: rem(1) solid var(--viol, #A414FF);
            background: rgba(164, 20, 255, 0.10);
            font-size: rem(13);
            font-weight: 400;
            line-height: rem(20);

            p {
                margin-top: rem(2);
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(35);
            height: rem(35);
            border-radius: rem(4);
            color: var(--blue-20, rgba(53, 130, 246, 0.20));
            border: rem(1) solid var(--blue, #3582F6);
            background: var(--blue-20, rgba(53, 130, 246, 0.20));
        }
    }

    &__items {
        display: flex;
        flex-direction: column;

        &-zones {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: rem(1) solid rgba(255, 255, 255, 0.10);
            padding: rem(20) rem(0);
            gap: rem(30);
            height: rem(150);
        }

        &-duration {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: rem(20) 0;
            height: rem(150);
        }

        &-seperator {
            margin-top: auto;
        }

        &-left, &-right {
            width: 50%;
            gap: 15px;
        }

        &-prices {
            display: flex;
            flex-direction: column;
            gap: rem(10);
        }

        &-paragraph {
            color: #FFFFFF;
            opacity: .5;
            font-size: rem(14);
        }

        &-title {
            font-size: rem(15);
            color: #FFFFFF;
            font-weight: 400;
            line-height: rem(22);
            p {
                display: flex;
                flex-direction: column;
            }
        }

        &-days {
            display: flex;
            flex-wrap: wrap;
        }

        &-lines {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        &-line {
            margin-top: rem(10);
            width: rem(17);
            height: rem(2);
            background-color: white;
        }

        &-center {
            margin: 0 auto;
        }

        &-desc {
            width: 50%;
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 950px) {
    .tariffs {
        &__header {
            //padding: 0;

            &-left {
                justify-content: space-between;
            }

            &__inner {
                .btn {
                    justify-content: center;
                }
            }
        }

        &__block {
            border: rem(15) solid #1E1F24;
        }
    }
}



@media screen and (max-width: 767px) {
    .tariffs {
        margin-bottom: 30px;
        padding: rem(30) rem(0) rem(60) rem(0);

        &__header {
            gap: 20px;

            &-left {
                width: 100%;
                flex-direction: column;
                gap: rem(20);
            }

            &-select {
                width: 100%;
            }

            &-checkboxes {
                justify-content: space-between;
                font-size: rem(20);
                width: 100%;

                .checkbox {
                    font-size: rem(12);
                    white-space: pre;
                }
            }

            &__inner {
                padding-right: rem(30);
            }

            display: flex;
        }

        &__content {
            margin-top: rem(10);
            width: 100%;
            display: flex;
        }

        &__block {
            width: 100%;
        }
    }
}

.seperator {
    margin-top: auto;
    border-bottom: rem(1) solid rgba(255, 255, 255, 0.1);
}

body {
    .react-select-prefix__multi-value {
        background: rgba(53, 130, 246, 0.2);

        div {
            color: #3582F6;
        }
    }
}


@media screen and  (max-width: 465px){
    body {
        .tariffs {
            &-edit {
                padding: rem(30) rem(10);

                &-previewBlock {
                    h2 {
                        text-align: center;
                        margin-top: rem(50);
                    }
                }

                &-content {
                    &__text {
                        p {
                            font-size: rem(14);
                        }
                    }
                }
                &__addTitle {
                    font-size: rem(20);
                    font-weight: 400;
                }
            }
            &__header {
                flex-direction: column;
                padding: rem(10);



                &-title {
                    font-weight: 400;
                    line-height: rem(22);
                    font-size: rem(20);
                }
            }

            &__content {
                margin-top: rem(-20);
            }
        }
    }
}



