.popup {
    &__container {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: rem(4);
        background: #33343A;
        gap: rem(5);
        color: $white;
        z-index: 1;
    }

    &__block {
        padding: rem(7) rem(10);
        display: flex;
        align-items: center;
        gap: rem(10);
        max-height: rem(36);
        min-height: rem(36);
        position: relative;
        max-width: rem(253);
        min-width: rem(253);
        width: 100%;
		cursor: pointer;

        &:hover {
            background: rgba(255, 255, 255, 0.05);

            .popup__block-right {
                opacity: 1;
                visibility: visible;
            }
        }

        &.hovered {
			cursor: e-resize;

            .popup__block-right {
                opacity: 1;
                visibility: visible;
            }
        }

        &.disabled {
            opacity: 0.25;
			cursor: no-drop;
        }

        &-icon {
            display: flex;
            align-items: center;
        }

        &-name {
            font-size: rem(14);
            line-height: rem(22);
        }

        &-right {
            left: 100%;
            opacity: 0;
            visibility: hidden;
            margin-left: rem(5);

            &-icon {
                margin-left: auto;
                transform: rotate(-90deg);
            }
        }
    }
}

.computers__sale {
    display: flex;
    flex-wrap: wrap;

    &__item {
        &__header {
            display: flex;
            margin-top: rem(30);

            h3 {
                font-size: rem(20);
                color: #FFFFFF;
                line-height: rem(28);
                font-weight: 400;
            }


		}
	}

	&__center {
		display: flex;
		gap: rem(30);
		width: 100%;

		&__block {
			width: 100%;
			min-height: rem(543);
			display: flex;
			max-width: rem(310);
			flex-direction: column;

			&__discount {
				margin-top: auto;

				&__btn {
					margin-top: rem(20);
					justify-content: space-between;
					flex-wrap: wrap;

					.btn__discount {
						padding: rem(14);
						border-radius: rem(4);
						opacity: 0.5;
					}

					.btn + .btn {
						margin-left: rem(15);
					}

				}
			}

			&__login {

				p {
					text-align: center;
					color: #FFFFFF;
					opacity: 0.5;
				}
			}
		}

		&__info {
			width: 100%;

			&__tariff {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: rem(60);

				.form-group {
					margin-bottom: rem(0);
				}

				&__middle {

					.btn {
						color: #3582F6;
						border-color: #3582F6;
					}

					.btn:hover {
						background-color: rgba(53, 130, 246, 0.2);
					}
				}

			}

			&__bottom {
				border-radius: rem(4);
				background: rgba(255, 255, 255, 0.05);
				min-height: rem(463);
				margin-top: rem(30);
			}
		}

		&__right {
			display: flex;
			padding: rem(15);
			flex-wrap: wrap;
			max-width: rem(300);
			width: 100%;
			justify-content: center;
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.05);

			&__check {
				max-height: rem(324);
				height: 100%;
				width: 100%;
				overflow: auto;

				&-center {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 100%;
				}

				p {
					text-align: center;
					font-size: rem(14);
					color: #FFFFFF;
					line-height: rem(18);
				}
			}

			&__payment {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 100%;

				&__cost {
					display: flex;
					flex-direction: column;

                    &__time {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
						color: #FFFFFF;

                        p {
                            font-size: rem(14);
                            line-height: rem(22);
                        }

					}
				}

				&__button {
					display: flex;
					margin: rem(15) 0 0;
					flex-direction: column;
					gap: rem(10);

					&__cash {
						display: flex;
						gap: rem(10);

						.btn {
							margin-left: rem(0);
							border-color: #3582F6;

							&:hover, &.active {
								background-color: rgba(53, 130, 246, 0.2);
								border-color: #3582F6;
							}
						}
					}

					&__module {
						.btn.btn-primary:disabled {
							background: #303138;
						}
					}
				}

			}
		}
	}

}

.search__dropdown {
	&__containers {
		position: relative;
	}

	&__block {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 5;
		background-color: #1E1F24;
		border-radius: rem(4);
		width: 100%;
	}

	&__item {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.05);
		border-radius: rem(4);
		padding: rem(14);
		color: $white;
		display: flex;
		align-items: center;
	}
}

