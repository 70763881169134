.carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(17);
    transition: .9s all ease;

    &-numbers {
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-number {
        background: none;
        border: none;
        color: #ffffff;
        font-size: rem(20);
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 10px;
        transition: 0.6s all ease;
        opacity: .5;

		div {
			font-size: rem(16);
			display: flex;
			align-items: center;
			vertical-align: middle;
		}
    }

    &-isActive {
        color:  #3582F6;
        opacity: 1;
    }

    &-btn {
        width: rem(30);
        background: none;
        border: none;
    }

    &-prev {
        transform: rotate(180deg);
        //margin-top: rem(5);
    }
    &-next {
        //margin-top: rem(-5);
        cursor: pointer;
    }
}

