.footer {
	padding-top: rem(15);
	background-color: #1E1F24;

	&__social, &__info {
		display: flex;
		margin: rem(30) 0;
	}

	&__social {
		.btn {}
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p, a {
			color: rgba(255, 255, 255, 0.50);
			font-size: rem(14);
			line-height: rem(18);
		}

		a+a {
			margin-left: rem(18);
		}

		&-right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}
