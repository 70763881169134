.login-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.container-fluid {
		z-index: 10;
	}

	.logo {
		margin-bottom: rem(125);
	}

	.sign-bg {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		position: relative;
		background-color: #1E1F24;
	}
}

.sign-in-form {
	background: rgba(255, 255, 255, 0.05);
	padding: rem(30);
	border-radius: rem(4);
	max-width: rem(406);
	width: 100%;
	color: $white;

	.btn-blue {
		min-width: rem(112);
		max-width: rem(112);
		max-height: rem(40);
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btn.btn-blue{
		font-size: rem(14);
	}

	.btn.btn-icon {
		color: #3582F6;
		text-align: right;
		font-size: rem(12);
		line-height: rem(22);
		cursor: pointer;
	}

	.btn-confirm {
		width: 100%;
		font-size: rem(18);
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: rem(80);
	}

	h4 {
		margin-bottom: rem(30);
		font-size: rem(20);
		line-height: rem(22);
		text-align: center;
	}

	.form-group {
		margin-bottom: rem(10);

		label {
			font-size: rem(14);
			margin-bottom: rem(10);
		}
		input {
			min-height: rem(56);
		}
	}

	button[type="submit"] {
		min-height: rem(56);
	}

	.input-group-append {
		.form-control {
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&-guest {
		padding: rem(10) rem(10) 0;
		display: flex;
		justify-content: center;

		.btn.btn-icon {
			font-size: rem(14);
			opacity: 0.8;
			cursor: pointer;
			color: white;
		}
	}

	&-back {
		position: absolute;
	}
}

.sign-in-footer {
	display: flex;
	background-color: $white;
	padding-left: rem(140);
	padding-right: rem(140);
	justify-content: space-between;
	padding-top: rem(15);
	padding-bottom: rem(15);
	.cm-col {
		display: flex;
		.vsk-logo {
			background-size: contain;
			background-repeat: no-repeat;
			width: 150px;
			background-position: center;
		}
		&:last-child {
			justify-content: flex-end;
		}
	}
	p {
		font: {
			family: "Montserrat";
			size: rem(12);
		}
		margin-bottom: 0;
	}
}

.auth__club {
	&__list {
		padding-bottom: rem(80);
	}

	&__block {
		padding: rem(15);
		border-radius: rem(4);
		background: rgba(255, 255, 255, 0.05);
		border: rem(1) solid transparent;
		margin-bottom: rem(10);
		cursor: pointer;

		&.active {
			border-color: rgba(255, 255, 255, 0.20);
			background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
		}

		&-name {
			color: $white;
			font-size: rem(15);
			line-height: rem(22);
		}

		&-address {
			font-size: rem(14);
			opacity: 0.75;
		}

		&-type {
			display: flex;
			align-items: center;
			padding-top: rem(10);

			&__btn {
				padding: rem(2) rem(8);
				color: #A4A4A4;
				text-align: center;
				font-size: rem(12);
				line-height:rem(20);
				border-radius: rem(4);
				background: rgba(255, 255, 255, 0.10);

				& + & {
					margin-left: rem(10);
				}

				&.active {
					background: linear-gradient(90deg, #467FFF 0%, #A414FF 100%);
					color: $white;
				}
			}
		}
	}
}

.sign-logo {
	position: absolute;
	top: rem(50);
	left: 50%;
	transform: translateX(-50%);
}
