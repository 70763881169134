.qr {
  &-block {
    padding: rem(20);
  }

  &-form {
    max-width: rem(700);
    text-align: center;

    h2 {
      margin-bottom: rem(10);
    }
    h4 {
      margin-top: rem(10);
      margin-bottom: 0;
    }
  }
}