.guest {
	&-container {
		padding: rem(15);
		height: calc(100vh - 73px);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.checkbox {
			width: 100%;
			justify-content: flex-start;
			margin-bottom: rem(15);

			a {
				color: $white;
				text-decoration: underline !important;
			}
		}
	}

	&__content__table {
		.table {
			min-width: rem(700);
		}
	}
}
