.tariffsAdd {
	color: white;
	padding: rem(30);

	&__creation {
		gap: rem(20);
	}

	&__creation, &__preview {
		margin-top: rem(15);
		display: flex;
		flex-direction: column;
	}

	&__inputs {
		display: flex;
		align-items: center;
		gap: rem(30);
		margin-top: rem(20);

		&-triple2 {
			display: flex;
			flex-direction: column;
			gap: rem(20);

			div {
				gap: rem(30);
				display: flex;
				align-items: center;
			}
		}

		&-input {
			height: rem(60);
			width: rem(500);
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.05);
			border: rem(1) solid rgba(255, 255, 255, 0.01);
			font-size: rem(14);
			font-weight: 400;
			color: white;
		}

		&-title {
			width: rem(206);
			font-weight: 400;
			line-height: rem(22);
			font-size: rem(15);
		}

		form {
			display: flex;
			flex-direction: column;
			gap: rem(10);
			align-items: flex-start;
		}

	}

	.btn {
		color: #3582F6;
	}

	&-title {
		font-weight: 400;
		line-height: rem(28);
		font-size: rem(20);
	}

	&__template {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: rem(30);
		width: 100%;

		&-twoButtons {
			display: flex;
			gap: rem(20);
			width: 70%;
		}

		&-delete {
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: rem(46);
			background: none;
			border: rem(1) solid red;
			cursor: pointer;
		}
		&-edit {
			width: 90%;
			color: #FFFFFF !important;
			display: flex;
			justify-content: center;
		}

		&-selection2 {
			width: 50%;
		}

		&-selection3 {
			width: 70%;

			div .react-select-prefix {
				&__multi-value {
					margin: 0;
					&__remove {
						padding-left: 0;
						padding-right: 0;
					}
				}
				&__indicator {
					padding: 0;
				}

				&__dropdown-indicator {
					padding: 0 rem(5);
				}
			}

		}

		&-blocks {
			display: flex;
			flex-direction: column;
			gap: rem(10);
		}

		&-single {
			width: 70%;
			position: relative;
			//height: 3.125rem;
		}

		&-double {
			width: 70%;
			display: flex;
			gap: rem(10);
			position: relative;
			height: 3.125rem;
			&-input {
				width: 50%;
			}
		}

		&-triple {
			width: 70%;
			height: 3.125rem;
			display: flex;
			align-items: center;
			gap: rem(10);

			&_input {
				display: flex;
				align-items: center;
				height: 3.125rem;
				gap: rem(1);
				width: 40%;
				input {
					padding-left: 40px !important;
				}
			}
		}

		&-toggle {
			width: 70%;
		}

		&-input {
			border-radius: rem(4);
			color: white;
			height: 100%;
			width: 100%;
		}

		&-title {
			width: 30%;
			font-size: rem(15);
			font-weight: 400;
			line-height: rem(22);
		}

		&-button {
			width: 70%;
			display: flex;
			justify-content: center;
			font-size: rem(16);
			color: white !important;
		}

		&-checkbox {
			width: 70%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			div {
				font-size: rem(15);
				font-weight: 400;
				line-height: rem(22);
			}
		}

		&-add {
			width: 70%;
			display: flex;
			align-items: center;
			gap: rem(10);
		}

		&-toggleText {
			color: var(--white, #FFF);
			font-size: rem(14);
			font-weight: 400;
		}
	}


	&__block {
		display: flex;
		flex-direction: column;
		gap: rem(15);
		background: rgba(255, 255, 255, 0.05);
		margin-top: rem(20);
		border-radius: rem(4);
		padding: rem(20);

		&-title {
			font-size: rem(15);
			font-weight: 400;
			opacity: 0.75;
		}

		&-header {
			display: flex;
			gap: rem(15);
			margin-top: rem(10);
		}

		&-content {
			display: flex;
			flex-direction: column;
			gap: rem(20);
		}

		&-icon, &-btn {
			display: flex;
			align-items: center;
			padding: rem(5) rem(8);
			border-radius: rem(4);
			&-red {
				color: #A414FF;
				border: rem(1) solid var(--viol, #A414FF);
				background: rgba(164, 20, 255, 0.10);
			}
			&-blue {
				color: #3582F6;
				border: rem(1) solid var(--blue, #3582F6);
				background: var(--blue-20, rgba(53, 130, 246, 0.20));
			}
		}
	}

	//div .react-select-prefix__value-container {
	//	font-size: rem(14);
	//	padding: rem(10);
	//}
}

@media screen and (max-width: rem(1530)) {
	.tariffsAdd{
		&__template {
			&-selection3 {
				width: 100%;
			}

			&-triple {
				flex-direction: column;
				margin-bottom: rem(50);

				&_input {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (min-width: rem(2000)) {
	.tariffsAdd {
		&__template {
			&-title {
				font-size: rem(20);
			}
		}
	}
}

@media screen and (max-width: rem(1240)) {
	.tariffsAdd {
		&__creation {
			width: 120%;
		}

		&__preview {
			margin-left: rem(-40);
			&-title {
				margin-left: rem(-30);
			}
		}

		&__template {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: rem(10);

			&-selection3 {
				width: 100%;
			}

			&-triple {
				flex-direction: column;
				margin-bottom: rem(50);

				&_input {
					width: 100%;
				}
			}

			&-checkbox {
				margin-top: rem(-10);
			}
		}

		&__block {
			margin-left: rem(-30);
			min-width: rem(230);
			padding: rem(10);

			&-icon, &-btn {
				padding: rem(2) rem(4);
			}

			&-content {
				gap: rem(60);

				div {
					gap: rem(15);

				}
			}
		}

		div .react-select-prefix__value-container {
			font-size: rem(12);
		}
	}
}

@media screen and (max-width: rem(770)) {
	.tariffsAdd {
		&-title {
			margin: 0 auto;
		}

		&__preview {
			margin-left: rem(100);
			&-title {
				margin-left: rem(0);
			}
		}

		&__content {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			&-preview {
				width: 100%;
				margin-left: 0;
			}
		}

		&__creation {
			width: 100%;
			margin-top: rem(30);

			&-title {
				top: -3%;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0%);
			}
		}

		&__preview {
			width: 100%;
			margin-left: 0;
			margin-bottom: rem(60);
			margin-top: rem(50);
		}

		&__block {
			margin-left: rem(0);
			min-width: rem(260);
			padding: rem(12);
			width: 100%;

			&-icon, &-btn {
				padding: rem(5) rem(8);
			}

			&-content {
				gap: rem(60);

				div {

				}
			}
		}

		&__template {
			&-single,
			&-double,
			&-triple,
			&-toggle,
			&-button {
				width: 100%;
			}
			&-twoButtons {
				width: 100%;
			}

			&-checkbox {
				margin-top: rem(-10);
			}
		}
	}
	//div .react-select-prefix__value-container {
	//	font-size: rem(10);
	//	padding: rem(5);
	//}
}

input[type="time"]::-webkit-calendar-picker-indicator {
	display: none;
}

.whiteSelection {
	div {
		color: white;
	}
}
.greySelection {
	div {
		&:nth-child(1) {
			color: grey;
		}
	}
}


