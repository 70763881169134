.chart {
    display: flex;
    align-items: center;
    gap: rem(20);
    height: 100%;
    width: 100%;
    margin-bottom: rem(50);

    &__bottom {
        display: flex;
        justify-content: space-between;
        height: 20px;
        gap: 10px;
        margin-left: -60px;
        &-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            text-align: center;
            min-width: 50px;
            max-width: 130px;
            overflow: hidden;
            font-size: 12px;
            //text-overflow: ellipsis;
            //white-space: nowrap;
            text-wrap: wrap;
            height: 100px;
        }
        &-zone {
            opacity: .5;
        }

        &-device {
            width: 50px;
            white-space: nowrap;
            display: flex;
            align-items: center;
        }
    }

    &__chart {
        width: 100%;
        position: absolute;
        //height: 400px;
        height: 100%;
        min-height: 390px ;
        //top: -2.5%;
        min-width: rem(1000);

        &-overview {
            min-height: 360px ;
            canvas {
                margin-left: -10px !important;
            }
        }

        canvas {
            margin-left: -40px;
        }

    }

    &__numbers {
        display: flex;
        flex-direction: column;
        width: 7%;
        text-align: end;
        gap: rem(55);
        margin-top: rem(-16);
        justify-content: space-between;
        &-number {
            height: rem(1);
        }
    }

    &__lines {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: rem(55);
        justify-content: space-between;
        margin-top: rem(10);
        &-line {
            background: rgba(255, 255, 255, 0.05);
            height: rem(1);
            min-width: rem(1000);
        }
    }
}

@media screen and (min-width: 1900px) and (max-width: 2099px){
    .chart{
        &__chart {
            min-height: rem(390);

            &-overview {
                min-height: rem(360);
            }
        }
    }
}

@media screen and (min-width: 2100px){
    .chart{
        &__chart {
            min-height: rem(380);

            &-overview {
                min-height: rem(360);
            }
        }
    }
}
//
//@media screen and (min-width: 1600px) {
//    .chart{
//        &__chart {
//            min-height: rem(400);
//        }
//    }
//}


