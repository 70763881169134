
.search__block {
	position: relative;
	padding-bottom: rem(15);

	&-field {
		position: relative;

		.form-icon-left {
			img {
				max-width: rem(25);
				max-height: rem(25);
				border-radius: 50%;
			}
		}
	}

	&__clear {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		padding: rem(14);
		z-index: 2;

		.btn {
			cursor: pointer;
		}
	}

	&-textField {
		&.active {
			.picker-wrapper input {
				padding: rem(14) rem(14) rem(14) rem(28 + 20 + 14);
			}
		}
	}

	&-dialog {
		position: absolute;
		top: calc(100% - 15px);
		left: 0;
		width: 100%;
		z-index: 1;
		padding-bottom: rem(16);
		overflow: auto;
		//opacity: 0;
		//z-index: -1;
		//visibility: hidden;
		//@include transition();
		//
		//&.active {
		//	z-index: 1;
		//	opacity: 1;
		//	visibility: visible;
		//}
	}

	&-items {
		padding: 0;
		background-color: #292A2F;
	}

	&-item {
		display: flex;
		align-items: center;
		padding: rem(13) rem(14);
		border-top: 1px solid rgba(255, 255, 255, 0.05);
		cursor: pointer;

		&:hover, &:focus {
			background: linear-gradient(0deg, rgba(53, 130, 246, 0.05) 0%, rgba(53, 130, 246, 0.05) 100%), #292A2F;
		}

		p {
			color: $white;
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(22);
			margin-left: rem(14);
		}
	}

	&-img {
		display: flex;
		border-radius: 50%;
		max-width: rem(32);
		min-width: rem(32);
		max-height: rem(32);
		min-height: rem(32);
	}
}










































