.tariffs-edit {
    padding: rem(30);
    color: #FFFFFF;

    &__content {
        display: flex;
        div {
            margin-bottom: 0;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: rem(30);

        &-content {
            display: flex;
        }
    }

    &__inputs {
        display: flex;
        gap: rem(10);
        flex-wrap: wrap;
        align-items: center;
        padding-right: rem(20);
		width: 100%;

        div {
            margin-bottom: 0;
        }
    }

    &__preview {
        width: 100%;
        padding: rem(15);
        margin-top: rem(30);
        display: flex;
        flex-direction: column;
        background: hsla(0,0%,100%,.05);
        border-radius: 0.25rem;
        min-width: rem(250);
    }

    &__titles {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: rem(-30);
    }

    &__form {
        &-multi {
            margin-bottom: 0;
            max-width: calc((100% - (0.625rem * 2) - -10px) / 2);
            width: 100% !important;
        }
    }

    &-space {
        margin-top: rem(20);
    }

    h2 {
        color: #FFFFFF;
        font-size: rem(24);
    }

    h3 {
        display: flex;
        align-items: center;
        font-size: rem(14);
    }

    &-addBtn {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: #FFFFFF;
        margin-top: -20px;
    }

    &__toggle {
        width: 100%;
    }

    &__addTitle {
        margin-left: rem(-18);
    }

    &-delete {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__deleteItems {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    &-priceField {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }
    &-delete {
        position: absolute;
        left: 105%;
        margin-top: 65px;
        cursor: pointer;
        &__none {
            display: none;
        }
    }

    &-content {
        &__text {
            display: none;
            width: 100%;
            align-content: center;
            gap: rem(10);
        }
    }

    &__btns {
        width: 100%;
        margin-top: rem(30);
        &-btn {
            display: flex;
            gap: rem(20);
            width: 100%;
            padding-right: rem(20);
        }
    }
    &-createBtn {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}

@media  screen and (max-width: 1130px){
    .tariffs-edit {
        &-previewBlock {
            margin-left: rem(-30);
            min-width: rem(230);
        }
    }
}

@media screen and (max-width: 1000px) {
    .tariffs-edit{
        &-space {
            margin-top: 0;
        }
        &__titles {
            display: none;
        }
        &-content{
            width: 100%;
            margin-left: rem(-15);
            &__text {
                display: flex;
            }
        }

        &__left {
            &-content {
                margin-left: rem(-15);
            }
        }

        &-previewBlock {
            min-width: rem(250);
            margin-left: rem(-25);
        }
    }
}

@media screen and (max-width: 770px) {
    .tariffs-edit {
        &-previewBlock {
            min-width: rem(250);
            margin-left: rem(-20);
        }
    }
}

@media screen and (max-width: 776px) {
    .tariffs-edit {
        padding: 10px;
        &__left {
            &-content {
                margin-left: 0;
            }
        }
        &__content {
            flex-direction: column;
            margin-bottom: rem(80);
        }

        &__inputs {
            padding-right: 0;
            padding-bottom: 0 !important;
        }
        &-previewBlock {
            margin-left: 0;
            width: 100%;
            margin-top: rem(30);
            margin-bottom: rem(40);
        }
        &__addTitle {
            margin-left: 0;
        }
    }
}



