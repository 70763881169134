.map-center {
	height: 100%;
	overflow: auto;

	&__content {
		display: flex;
		align-items: center;
		border-radius: rem(4);
		gap: rem(30);
		flex-wrap: wrap;
	}

	&__items {
		//width: 100%;
		gap: rem(30);
		padding: rem(30);
		//max-width: rem(314);
		border-radius: rem(4);
		background: rgba(255, 255, 255, 0.05);
		min-width: rem(314);

		display: flex;
		justify-content: space-between;
	}

	&__devices {
		//width: 100%;
		gap: rem(10);
		//padding: rem(30);
		//max-width: rem(314);
		border-radius: rem(4);
		//background: rgba(255, 255, 255, 0.05);
		min-width: rem(314);

		display: flex;
		justify-content: space-between;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		padding: rem(10) 0;
	}

	&__device {
		display: flex;
		justify-content: space-between;
		padding: rem(5) 0;
	}

	&__saveBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: rem(30);
		width: 100%;
	}

	&__selected {
		background-color: #70D584;
	}

	&__selected, &__selected-device {
		border-radius: rem(8);

		.btn {
			border-color: transparent;
		}

		div {
			color: #000000;
			font-size: rem(18);
		}
	}

	&__block {
		width: rem(138);
		height: rem(110);

		.btn {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: rem(8);
			border: rem(1) solid rgba(255, 255, 255, 0.10);
			word-break: break-all;

			div {
				color: $black;
			}

			&:hover, &:focus {
				div {
					color: $black;
				}
			}

			& > * {
				pointer-events: none;
				font-size: rem(14);
			}
		}

		&-device {
			width: rem(93.7);
			height: rem(82);

			.btn {
				justify-content: flex-start;
				padding: rem(12);
				text-align: left;

				&:hover, &:focus {
					div {
						color: $white;
					}
				}

				.number {
					border-radius: 50%;
					background: rgba(255, 255, 255, 0.05);
					width: rem(24);
					height: rem(24);
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: rem(12);
				}

				.header__text {
					justify-content: flex-start;
				}

				div {
					color: $white;
					overflow: hidden;
					width: 100%;
				}

				.block {
					display: flex;
					align-items: center;
				}

				.icon {
					min-width: rem(18);
				}

				.plus {
					display: flex;
					justify-content: center;
					width: 100%;

					svg {
						opacity: 0;
						transition: .3s all;
					}
				}

				.text {
					padding-left: rem(6);
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				&:hover, &:focus {
					background-color: #FFFFFF0D;

					svg {
						opacity: 1;
					}
				}
			}
		}
	}

	&__popup {
		&__block {
			margin-top: rem(100);
			display: flex;
			flex-direction: column;
			position: absolute;
			width: rem(250);
			gap: rem(10);
			border-radius: rem(4);
			background: #33343A
		}

		&__item {
			padding: rem(10) rem(10) 0;

			.btn {
				justify-content: center;
				width: 100%;
				max-height: rem(40);
			}
		}

		&__header {
			display: flex;
			align-items: center;
			padding: rem(10) rem(15);
			gap: rem(10);
			border-bottom: rem(1) solid rgba(255, 255, 255, 0.09);
		}

		&__content {
			display: flex;
			align-items: center;
			padding: 0 rem(15) rem(10) rem(15);
			gap: rem(10);
		}

	}

	&__console {
		&-border {
			padding: rem(20) rem(30);
			justify-content: center;
		}

		&-item {
			justify-content: center;
			width: 100%;
		}

		&__selected {
			background-color: #70D584 !important;
			max-width: rem(138 * 2);
			min-width: rem(138 * 2);
			width: 100%;
		}

		&-block {
			display: flex;
			justify-content: center;
			border: rem(1) solid  rgba(255, 255, 255, 0.1);
			border-radius: rem(8) rem(8) rem(50) rem(50);
			overflow: hidden;
			width: 100%;

			.map-center__block + .map-center__block {
				.btn {
					border-left: rem(1) solid rgba(255, 255, 255, 0.1);
				}
			}

			.btn {
				border: 0;
				border-radius: 0;
			}
		}
	}

	&-main {
		padding-right: rem(20);
		overflow: auto;
	}
}
