.settings {
	padding: rem(30) 1.875rem rem(30) 1.875rem;
	display: flex;
	align-items: center;
	gap: rem(30);
	justify-content: center;
	flex-direction: column;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: rem(20);

		&-button {
			display: flex;
			align-items: center;
			padding: rem(8) rem(14);
			font-size: rem(15);
			font-weight: 400;
			color: lightgray;
			border-radius: rem(4);
			border: 1px solid var(--blue-20, rgba(53, 130, 246, 0.20));
			opacity: 0.4;
			background: rgba(255, 255, 255, 0.25);
			white-space: nowrap;
		}

		&-active {
			display: flex;
			align-items: center;
			padding: rem(8) rem(14);
			color: lightgray;
			border-radius: rem(4);
			border: rem(1) solid var(--blue-20, rgba(53, 130, 246, 0.20));
			background: var(--blue-20, rgba(53, 130, 246, 0.20));
			white-space: nowrap;

			span {
				svg {
					g {
						path {
							stroke: #3582F6;
						}
					}

				}

			}
		}
	}

	&__block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: rem(30);
		width: 100%;
		padding: rem(30) 0;
		border-bottom: rem(1) solid rgba(255, 255, 255, 0.10);

		&-left, &-right {
			width: 50%;
			display: flex;
			flex-direction: column;
		}

		&-left {
			gap: rem(5);
		}

		&-right {
			gap: rem(10);

			&-btn {
				flex-direction: row;
				justify-content: flex-end;

				.btn {
					max-width: rem(200);
				}
			}
		}

		&-timer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			div {
				background: rgba(255, 255, 255, 0.10);
				padding: rem(14);
				color: #FFFFFF;
				border-radius: rem(4);
			}
		}

		&-title {
			font-size: rem(15);
			line-height: rem(22);
			font-weight: 400;
			color: #fff;
		}

		&-desc {
			color: #fff;
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(18);
			opacity: 0.75;
		}

	}

	&__content {
		&__direction {
			padding: rem(10);

			h4 {
				color: $white;
			}
		}
	}
}

.convert__modal {
	&-center {
		padding: 0 0 rem(16);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: rem(31);
		max-height: rem(31);
		border-radius: 50%;
		padding: rem(7);
		background-color: rgba(255, 255, 255, 0.05);
		opacity: 0.5;

		& div {
			display: flex;
			padding: 3.286px 0.75px 2.436px 1.25px;
			justify-content: center;
			align-items: center;
		}
	}

	.btn-link {
		border: none;
		color: #3582F6;

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}


@media screen and (min-width: rem(2000)){
	.settings {
		&__header {
			&-button {
				padding: rem(12) rem(28);
				font-size: rem(22);
			}
		}
	}
}

@media screen and (min-width: rem(1440)){
	.settings {
		&__header {
			&-button, &-active {
				font-size: rem(14);
				span {
					width: rem(20);
					height: rem(2);
				}
			}
		}
	}
}
@media screen and (min-width: rem(1024)) and (max-width: rem(1339)){

	.settings {
		&__header {
			&-active, &-button {
				padding: rem(5);
				font-size: rem(8);
				span {
					width: rem(12);
					height: rem(12);
				}
			}
		}
	}
}

@media screen and (min-width: rem(768)) and (max-width: rem(1380)){
	.settings {
		&__header {
			width: 220%;
			gap: rem(5);
			&-button, &-active {
				font-size: rem(12);
			}
		}
	}
}
