.reports {
    position: relative;
    padding: 1.875rem 0.9375rem;
    height: 100%;
    color: #FFFFFF;

    &-date {
        min-width: rem(200);
    }

    &__header {
        overflow-x: auto;
        display: flex;
        justify-content: space-between;
        gap: rem(18);

        &-button {
            display: flex;
            align-items: center;
            padding: rem(8) rem(14);
            font-size: rem(15);
            font-weight: 400;
            color: lightgray;
            border-radius: rem(4);
            border: rem(1) solid rgba(53, 130, 246, 0.20);
            opacity: 0.4;
            background: rgba(255, 255, 255, 0.25);
            white-space: nowrap;
			width: 100%;
        }

        &-active {
            background: rgba(53, 130, 246, 0.20);
			opacity: 1;

            span {
                svg{
                    g {
                        path{
                            stroke: #3582F6;
                        }
                    }

                }

            }
        }
    }

    &__subheader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem(30);

        &-left {
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            padding: rem(20);
        }

        &-right {
            display: flex;
            align-items: center;
			flex-wrap: wrap;
            gap: rem(21);

            &-block {
                border-radius: rem(4);
                background: rgba(255, 255, 255, 0.05);
                padding: rem(20);
            }
        }
    }

    &__filter {
        margin-top: rem(30);
        display: flex;
        align-items: center;
		flex-wrap: wrap;
        gap: rem(10);

        &-dates {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        &-line {
            width: rem(20);
            height: rem(2);
            background-color: #FFFFFF;
            opacity: .5;
        }

        &-buttons {
            display: flex;
            align-items: center;
            gap: rem(30);
        }

        &-selection {
            width: rem(262);
        }
    }

    &__shift {
        margin-top: rem(30);
        table {
            background: rgba(255, 255, 255, 0.05);
            border-radius: rem(4);
            tbody {
                tr {
                    background: none;
                    white-space: nowrap;
                    &:hover {
                        background: none;
                    }
                }
            }
            thead {
                tr {
                    th {
                        opacity: .5;
                    }
                }
            }
            td {
                border-top: none;
                padding: rem(0) rem(12) rem(20) rem(12);
            }
        }

        &-notFound {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(750);
        }

        &-title {
            margin-top: rem(30);
            font-size: rem(20);
        }

        &-subtitle {
            font-size: rem(14);
            opacity: .5;
        }

        &-content {
            overflow-x: auto;
            display: flex;
            flex-direction: column;
            margin-top: rem(20);
        }

        &-block {
            padding: rem(15) rem(25);
            display: flex;
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            gap: rem(50);
        }

        &-items {
            display: flex;
            flex-direction: column;
            gap: rem(15);
        }

        &-dates {
            display: flex;
            align-items: center;
            gap: rem(5);
        }

        &-bonus {
            color: #F9D448;
            font-size: rem(12);
            padding: rem(5);
            display: flex;
            align-items: center;
            gap: rem(2);
            border-radius: rem(4);
            margin-top: rem(-6);
            background: var(--20, rgba(249, 212, 72, 0.20));
        }
    }

    &__rating {
        &-notFound {
            height: rem(200);
        }
    }

    &__user {
        &-header {
            margin-top: rem(30);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-dates {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        &-content {
            overflow-x: auto;
            min-height: rem(510);
            margin-top: rem(30);
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
        }

        &-notFound {
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            margin-top: rem(30);
            tbody {
                tr {
                    background: none;
                    white-space: nowrap;
                    &:hover {
                        background: none;
                    }
                }
            }
        }

        &-user {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3582F6;
            padding: rem(2) rem(8) ;
            border-radius: rem(4);
            border: rem(1) solid var(--Blue, #3582F6);
        }
        &-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(600);
        }
    }

    &__occupancy {
        &-content {
            margin-top: rem(30);
            padding: rem(30) rem(30) rem(120) rem(30);
            height: 100%;
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
			overflow: auto;
        }

        &-table {
            overflow-x: auto;
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            margin-top: rem(30);
        }

        &-percent {
            border: rem(1) solid #3582F6;
            color: #3582F6;
            margin-left: rem(10);
            padding: rem(2) rem(10);
            border-radius: rem(20);
        }

        &-title {
            font-size: rem(20);
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
			flex-wrap: wrap;
        }

        &-line {
            width: rem(20);
            height: rem(2);
            background: #FFFFFF;
            margin-bottom: rem(10);
        }

        &-btns {
            display: flex;
            align-items: center;
			flex-wrap: wrap;
            gap: rem(20);
        }

        &-notFound {
            text-align: center;
            opacity: .5;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
        }

        &-btn {
            border-radius: rem(4);
            border: 1px solid rgba(255, 255, 255, 0.10);
            padding: rem(5);
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        &-subheader {
            margin-top: rem(30);
            display: flex;
            justify-content: space-between;
        }

        &-block {
            display: flex;
            flex-direction: column;
            gap: rem(10);
        }

        &-22Px {
            font-size: rem(22);
        }

        &-16Px {
            font-size: rem(16);
        }

        &-nonActive {
            background: none;
            border: none;
            color: #fff;
            padding: rem(12) rem(20);
        }

        &-chart {
            display: flex;
            flex-direction: column;
            gap: rem(30);
            margin-top: rem(30);
        }
    }

    &__overview {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
			flex-wrap: wrap;
            margin-top: rem(30);
            gap: rem(50);


            &-block {
                display: flex;
                flex-direction: column;
                gap: rem(20);
            }

            &-left {
                //height: rem(140);
                display: flex;
                align-items: center;
				flex-wrap: wrap;
                gap: rem(5);
                border-radius: rem(4);
                background: rgba(255, 255, 255, 0.05);
                padding: rem(20);

                .reports__filter-line {
                    margin-top: rem(25);
                }
            }

            &-right {
                height: rem(140);
                width: rem(180);
                display: flex;
                flex-direction: column;
                gap: rem(20);
                border-radius: rem(4);
                background: rgba(255, 255, 255, 0.05);
                padding: rem(20);
            }


            &-title {
                font-size: rem(12);
            }

            &-dates {
                display: flex;
                gap: rem(10);
            }

            &-date {
                width: rem(140);
            }

            &-numbers {
                margin-top: rem(10);
                display: flex;
                flex-direction: column;
                gap: rem(30);
            }

        }

        &-content {
            //position: relative;
            //border-radius: rem(4);
            //padding: rem(30);
            //padding-bottom: rem(120);
            //background: rgba(255, 255, 255, 0.05);
            //margin-top: rem(30);
            //align-items: center;
            //gap: rem(10) !important;
			//overflow: auto;
            margin-top: rem(30);
            padding: rem(30) rem(30) rem(80) rem(30);
            height: 100%;
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            overflow: auto;
        }

        &-title {
            font-size: rem(20);
        }

        &-chart {
            margin-top: rem(40);
        }

        &-white {
            width: rem(24);
            height: rem(2);
            background-color: white;
        }

        &-blue {
            width: rem(22);
            height: rem(1);
            background-color: #3582F6;
        }

        &-value {
            margin-top: rem(-8);
            height: rem(12);
        }

        &-notFound {
            height: rem(580);
            position: relative;
            border-radius: rem(4);
            padding: rem(30);
            padding-bottom: rem(60);
            background: rgba(255, 255, 255, 0.05);
            margin-top: rem(30);
            align-items: center;
            gap: rem(10);

            &-title {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: rem(14);
                opacity: .5;
                transform: translate(-50%, -50%);
            }

            h1 {
                font-size: rem(20);
            }
        }
    }
}


@media screen and (max-width: 465px){
    body {
        .reports {
            &__filter{
                &-buttons {
                    button {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            &__shift {
                &-notFound {
                    height: rem(500);
                }
            }
        }
    }
}