@font-face {
    font-family: "PT Sans";
    src: url("../fonts/PTSans-Regular.eot");
    src: url("../fonts/PTSans-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/PTSans-Regular.woff2") format("woff2"), url("../fonts/PTSans-Regular.woff") format("woff"),
        url("../fonts/PTSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PT Sans Bold";
    src: url("../fonts/PTSans-Bold.eot");
    src: url("../fonts/PTSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/PTSans-Bold.woff2") format("woff2"), url("../fonts/PTSans-Bold.woff") format("woff"),
        url("../fonts/PTSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors Light.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "TT Fors";
    src: url("../../public/fonts/tt/TT Fors Medium.ttf") format("ttf"),
        url("../../public/fonts/tt/TT Fors Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors DemiBold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors DemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors Bold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors ExtraBold.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "TT Fors";
	src: url("../../public/fonts/tt/TT Fors Black.ttf") format("ttf"),
	url("../../public/fonts/tt/TT Fors Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "SK WGF";
	src: url("../../public/fonts/sg-wgf/SK WGF.woff") format("woff"),
	url("../../public/fonts/sg-wgf/SK WGF.woff") format("truetype");
	font-weight: 400;
	font-style: normal;
}
