.discipline {
    &__add {
        &__btn {
            &__left {
                .btn:active,
                .btn:hover {
                    background: none !important;
                    background: var(--blue-20, rgba(53, 130, 246, 0.20)) !important;
                    border: rem(1) solid #3582F6 !important;
                    color: #3582F6;
                }

                .btn {
                    background: none !important;
                    border: rem(1) solid #3582F6 !important;
                    color: #3582F6;
                }
            }

            padding: rem(30) rem(30) 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #FFFFFF;

            h2 {
                font-size: rem(20);
                line-height: rem(28);
                color: $white;
            }

            .btn {
                max-width: rem(224);
                width: 100%;
                justify-content: center;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: rem(30);

            &__wrap {
                padding-bottom: rem(30);
                height: 100%;

                &__inner {
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.05);
                    padding: rem(20);
                    height: 100%;

                    &__info {
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        &__img {
                            text-align: center;
                            padding: rem(10);
                            border-radius: rem(5);
                            background: #16171B;

                            div {
                                width: 100%;
                                height: 100%;
                                max-height: rem(54);
                                min-height: rem(54);
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                            img {
                                object-fit: contain;
                            }
                        }

                        &__txt {
                            padding-top: rem(15);

                            p {
                                font-size: rem(18);
                                line-height: rem(22);
                                color: $white;
                            }
                        }

                        &__title {
                            padding-top: rem(10);

                            span {
                                font-size: rem(14);
                                line-height: rem(20);
                                color: $white;
                                opacity: 0.5;
                            }
                        }

                        &__button {
                            padding-top: rem(30);
                            display: flex;
                            align-items: center;
                            margin-top: auto;

                            .btn + .btn {
                                border-radius: rem(4);
                                background: #B4474E;
                                padding: rem(13) rem(14);
                                margin-top: auto;
                            }

                            .btn__discipline {
                                width: 100%;
                                padding: rem(14) rem(0);
                                justify-content: center;
                                margin-top: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    &__content {
        padding: rem(30);

        &__back {
            margin-bottom: rem(29);

            .btn {
                border: none;
                background-color: transparent;
                padding: 0;
                box-shadow: none;
                color: #3582F6;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            gap: rem(246);
        }

        h3 {
            font-size: rem(20);
            line-height: rem(22);
            color: $white;
            font-weight: 400;
            margin-bottom: rem(29);
        }

        &__form {
            display: flex;
            height: 100%;
            gap: rem(30);

            &__left {
                max-width: rem(415);
                width: 100%;
                height: 100%;

                & .dropdown__block {
                    margin-bottom: rem(16);
                }

                &__bottom {
                    margin-top: rem(57);

                    .btn {
                        width: 100%;
                        justify-content: center;
                    }
                }
            }

            &__right {
                max-width: rem(304);
                width: 100%;

                .form-file {
                    max-width: rem(205);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: rem(20);
                    min-height: rem(205);
                    border-radius: rem(4);
                    background: rgba(255, 255, 255, 0.05);

                    span {
                        font-size: rem(20);
                        line-height: rem(22);
                        color: $white;
                        opacity: 0.5;
                    }

                    svg {
                        width: rem(56);
                        height: rem(56);
                    }
                }

                &__file {
                    display: flex;
                    flex-direction: column;
                    gap: rem(30);
                }

                &__text {
                    display: flex;
                    align-items: baseline;
                    gap: rem(10);
                }
            }

        }
    }

}

@media screen and (max-width: 465px) {
    .discipline {
        &__content {
            &__back {
                width: 100%;
                display: flex;
                align-items: center;

                h3 {
                    margin-bottom: 0;
                    text-align: center;
                    width: 100%;
                }
            }

            &__form {
                &__right {
                    &__text {
                        justify-content: start;
                    }
                }

                &__left {
                    &__mobile {
                        &__text {
                            font-size: rem(14);
                            color: #FFFFFF;
                            margin-bottom: rem(10);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 465px) {
    body {
        .discipline {
            &__content__form__right__text {
                h3 {
                    font-size: rem(14);
                    margin-bottom: rem(10);
                }
            }

            &__add {
                &__list {
                    &__wrap {
                        &__inner {
                            padding: rem(10);

                            &__info {
                                &__button {
                                    gap: rem(10);

                                    .btn {
                                        height: rem(50);
                                        padding: rem(10);
                                        display: flex;
                                        justify-content: center;
                                        width: 50% !important;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


