
.dashboard {
    padding: rem(30) rem(15);

	&__header__subject {
		h2 {
			font-size: rem(20);
			line-height: rem(28);
			color: #FFFFFF;
			margin-bottom: rem(30);
		}
	}

    &__bg {
        border-radius: rem(4);
        background: rgba(255, 255, 255, 0.05);
        padding: rem(15);

        &__info {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &__meta  {
                padding: rem(2) rem(8);
                border-radius: rem(4);
                background: rgba(112, 213, 132, 0.10);
                display: flex;

                span {
                    font-size: rem(12);
                    color: #70D584;
                }
            }
        }

        &__caption  {
            display: flex;
            align-items: center;

            img {
                max-width: rem(44);
                border-radius: 50%;
            }
        }

        &__users {
            margin-left: rem(20);
            row-gap: rem(5);

            h4 {
                color: #FFFFFF;
                font-size: rem(20);
            }
        }

        p {
            font-size: rem(14);
            color: #FFFFFF;
            opacity: 0.5;
        }

        &__section {
            display: flex;
            align-items: center;
            gap: rem(30);
            margin-top: rem(10);

            span {
                font-size: rem(20);
                color: #FFFFFF;
            }
        }

        &__start-date {
            margin-top: rem(15);

            span {
                font-size: rem(14);
                color: #FFFFFF;
            }
        }
    }

    // DashboardInfo css

    .challenge-info__item {
        border-radius: rem(4);
        background: rgba(255, 255, 255, 0.05);
        padding: rem(30);
        margin-top: rem(30);

        .tabs-list_content {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0 0 rem(30);
            column-gap: rem(30);

            li {
                cursor: pointer;
                color: #FFFFFF;
                font-size: rem(16);
                opacity: 0.5;
                outline: none;
            }

            .react-tabs__tab--selected {
                opacity: 1;
            }
        }

        &__add {
            display: flex;
            column-gap: rem(30);
        }

        &__blocks {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: rem(4);
            border: rem(1) solid rgba(255, 255, 255, 0.05);
            background: rgba(255, 255, 255, 0.03);
            padding: rem(15);

            h4 {
                color: #FFF;
                font-size: rem(14);
            }

            &__control {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: rem(10);
            }

            p {
                font-size: rem(15);
                font-style: normal;
                font-weight: 400;
                line-height: rem(20);
                padding: rem(6) rem(14);
                border-radius: rem(4);
            }
        }
    }
}



















