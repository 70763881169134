.messenger {
    position: relative;
    display: flex;
    height: 100%;

    &__left {
        padding: rem(30) rem(30) !important;
        border-right: rem(1) solid rgba(255, 255, 255, 0.1 );
        &-items {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            margin-top: rem(30);
        }
    }

    &__problems {
        cursor: pointer;
        padding: rem(14);
        display: flex;
        justify-content: space-between;
        border-radius: rem(4);
        color: rgba(255, 255, 255, 0.5);

        &-active {
            color: #FFFFFF;
            background: rgba(255, 255, 255, 0.05);
        }

        &:hover {
            color: #FFFFFF;
            background: rgba(255, 255, 255, 0.05);
        }
    }

    &__right {
        width: 100%;
    }

    &-chat {
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #FFFFFF;

        &__header {
            display: flex;
            align-items: center;
            padding: rem(14) rem(38);
            width: 100%;
            background: rgba(217, 217, 217, 0.05);
            height: rem(64);
            gap: rem(10);

			img {
				max-width: rem(35);
				max-height: rem(35);
				overflow: hidden;
				border-radius: 50%;
				background-color: rgb(49, 50, 56);
			}

            &-desc {
                display: flex;
                flex-direction: column;
            }

            &-name {
                font-size: rem(14);
                line-height: rem(22);
                color: #FFFFFF;
            }

            &-problem {
                color: #FFFFFF;
                font-size: rem(14);
                line-height: rem(22);
                opacity: .5;
            }
        }

        &__content {
            overflow-y: auto;
            margin-top: rem(30);
            padding: rem(0) rem(30);
            display: flex;
            flex-direction: column;
            gap: rem(10);
            //max-height: calc(100vh - 64px - 114px - 73px);
			//height: 100%;
        }

        &__block {
            z-index: 1;
            display: flex;
            flex-direction: column;
            padding: rem(15) rem(20);

			p {
				word-wrap: break-word;
			}

            &-bottom {
                display: flex;
                align-items: center;
                gap: rem(5);
                align-self: flex-end;
                justify-self: flex-end;
                margin-left: rem(60);

                p {
                    font-size: rem(12);
                    opacity: .5;
                }
            }
        }

        &__response {
            z-index: 1;
            align-self: flex-start;
            max-width: 50%;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.04);
        }

        &__message {
            z-index: 1;
            align-self: flex-end;
            max-width: 50%;
            border-radius: 8px;
            background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100.93%);
        }

        &__date {
            z-index: 1;
            text-align: center;
            opacity: .5;
            font-size: rem(14);
            width: rem(100);
            align-self: center;
            margin-bottom: rem(20);
            border-radius: rem(37);
            padding: rem(5) rem(20);
            background: rgba(255, 255, 255, 0.05);
        }

        &__footer {
            //z-index: 5;
            //position: fixed;
            //bottom: 0;
            //width: 50%;
			margin-top: rem(30);
            gap: rem(10);
            display: flex;
            align-items: center;
            padding: rem(10) rem(30);
            background: #292A2F;
            justify-self: flex-end;
        }

        &__textField {
            z-index: 9999;
            width: 100%;

            div {
                margin-bottom: 0;
                background: #1E1F24;
                border-radius: rem(8);

                input {
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}
