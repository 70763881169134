.seasons {
	&__content__table {
		overflow-x: auto;

		.table {
			min-width: rem(360);
		}
	}

	&-page {
		display: flex;
		flex-direction: column;
		gap: rem(20);

		&__date {
			display: flex;
			align-items: center;
			flex-direction: column;

			.custom-calendar {
				width: 100%;
				margin-top: rem(10);
			}

			&-txt {
				width: 100%;
				padding-top: rem(5);

				p {
					color: $white;
					font-size: rem(16);
				}
			}
		}

		&__btn {
			margin-top: rem(80);
			display: flex;
			align-items: center;
			width: 100%;

			.btn {
				width: 100%;
				justify-content: center;
				height: rem(46);
			}
		}
	}
}

