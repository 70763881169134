.checkbox {
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	line-height: 22px;
	font-size: 15px;

	label {
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	input {
		position: relative;
		appearance: none;
		border-radius: 4px;
		border: 1px solid rgba(255, 255, 255, 0.20);
		min-width: 19px;
		height: 19px;
		margin-right: rem(10);
		cursor: pointer;

		&:checked {
			background-color: #3582F6;
			border-color: #3582F6;

			&:before {
				content: '';
				position: absolute;
				top: 9px;
				left: 9px;
				height: 2px;
				background-color: white;
				transform: rotate(40deg);
				margin-left: -6px;
				margin-top: 1px;
				width: 6px;
			}

			&:after {
				content: '';
				position: absolute;
				top: 9px;
				left: 9px;
				height: 2px;
				background-color: white;
				transform: rotate(130deg);
				margin-left: -4px;
				margin-top: -1px;
				width: 10px;
			}
		}
	}
}
