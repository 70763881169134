.mobile__block {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #1E1F24;
    z-index: 9999;

    &__elem__block {
        display: flex;
        background-color: rgba(255, 255, 255, 0.05);
        width: 100%;
        justify-content: space-around;
        align-items: center;
        padding: rem(15) rem(10) rem(22) rem(10);
    }

    p {
        font-size: rem(10);
        color: #FFFFFF;
        line-height: rem(14);
    }

    &__menu:nth-child(1) {
        display: flex;
        align-items: center;
        gap: rem(3);
        margin-right: rem(10);
        width: 100%;
        justify-content: space-around;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__menu:nth-child(3) {
        display: flex;
        align-items: center;
        gap: rem(3);
        margin-left: rem(10);
        width: 100%;
        justify-content: space-around;


        a {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__burger {
        display: flex;
        align-items: center;
        justify-content: center;
        border: rem(4) solid #16171B;
        min-height: rem(60);
        min-width: rem(60);
        overflow: hidden;
        padding: rem(11.7);
        margin-top: rem(-35);
        border-radius: 50%;
        background: (linear-gradient(90deg, #467FFF 0%, #A414FF 100%));
        box-shadow: 0px 4px 16px 0px rgba(120, 72, 255, 0.30), 0px 4px 8px 0px rgba(255, 255, 255, 0.30) inset;

        &__prime {
            display: flex;
        }
    }
}
