.person-edit {
	padding: rem(30) rem(15) 0;

	&__header {
		.btn {
			color: #3582F6;
		}

		h2 {
			font-size: rem(20);
			line-height: rem(28);
			color: #FFFFFF;
		}
	}
}

.edit__block {
	padding: rem(30) rem(15);

    &__item {
        .btn {
            color: #3582F6;
        }

        .btn.btn-danger {
            background-color: #B4474E;
        }

        h2 {
            font-size: rem(20);
            line-height: rem(28);
            color: #FFFFFF;
        }

        label {
            font-size: rem(14);
        }

        &__back {
            display: flex;
            align-items: center;
            gap: rem(10);
            margin-bottom: rem(20);
        }

        &__header {
            display: flex;
            align-items: center;
            gap: rem(10);
            margin-bottom: rem(20);
        }


        &__search {
            display: flex;
            align-items: center;
            margin-bottom: rem(10);
            justify-content: space-between;
        }

        &__edit {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: rem(20);
            margin-top: rem(20);
        }

        .form-group {
            margin: 0;
            max-width: rem(322);
            width: 100%;
            cursor: pointer;

			.custom-calendar {
				background-color: transparent;
				width: 100%;
			}
        }

        &__btn {
            max-width: rem(151);
            width: 100%;

            .btn {
                padding: rem(13) rem(20);
                border-radius: rem(4);
                font-size: rem(16);
                color: #FFFFFF;
            }

        }

        p {
            font-size: rem(15);
            color: #FFFFFF;
        }

        .line {
            height: rem(1);
            background: rgba(255, 255, 255, 0.10);
            width: 100%;
            margin: rem(20) rem(0);
        }

        form {
            max-width: rem(322);
            width: 100%;
        }

    }

    &__tariff {
        padding: rem(15);
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: rem(4);

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(10);

            &__pen {
                padding: rem(13) rem(17);
                border-radius: rem(4);
                background: rgba(53, 130, 246, 0.15);
            }

        }

        p {
            font-size: rem(12);
            color: #70D584;
            display: inline;
            padding: rem(4) rem(8);
            border-radius: rem(4);
            background-color: rgba(112, 213, 132, 0.10);
        }

        h4 {
            font-size: rem(15);
            line-height: rem(22);
            color: #FFFFFF;
            margin-bottom: rem(10);
        }

        .line {
            margin: rem(20) rem(0);
        }

        &__block-form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: rem(10);
        }

    }


    .form-group {
        margin: 0;
    }

    .edit__person__select {
        display: flex;
        flex-direction: column;
        gap: rem(10);

        &__switch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: rem(4);
            background: (rgba(255, 255, 255, 0.05));
            padding: rem(14);
			gap: rem(14);

			p {
				color: $white;
			}

            &__inclusion {

				.toggle__text {
					font-size: rem(14);
					line-height: rem(22);
					padding-right: rem(14);
				}

                h3 {
                    width: 100%;
                    font-size: rem(14);
                    color: #FFFFFF;
                }

                .tariffsAdd__template-toggle {
                    width: auto;
                }
            }

            .toggle {
                background: none;
				padding: 0;
				cursor: pointer;
            }
        }

		&__photo {
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.05);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.form-file {
				min-height: rem(205);
			}
		}
    }

}
