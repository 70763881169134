.table {
	th, td {
		color: #FFF;
		font-size: rem(14);
		font-style: normal;
		font-weight: 400;
		line-height: rem(22);
		border: 0;
	}

	thead th {
		border-bottom: 0;
	}

	tbody td {
		border-top: rem(1) solid rgba(255, 255, 255, 0.1);
	}

	&__header {
		.last {
			display: flex;
			justify-content: flex-end;
		}

		th, &-th {
			padding: rem(15) rem(12);
		}

		&__th {
			display: flex;

			p {
				margin-right: rem(5);
				line-height: rem(22);
				white-space: nowrap;
			}

			.btn {
				line-height: rem(14);
			}
		}

		&-th, &-td {
			color: #FFF;
			font-size: rem(14);
			font-style: normal;
			font-weight: 400;
			line-height: rem(22);
			border: 0;
		}
	}

	&__content {
		.last {
			text-align: right;
		}

		td, &-td {
			padding: rem(21) rem(12);
		}

		tr, &-tr {
			cursor: pointer;

			&:hover, &:focus {
				background: rgba(255, 255, 255, 0.1);
			}
		}

		&-loading {
			td {
				vertical-align: middle;
			}
		}

		&-th, &-td {
			color: #FFF;
			font-size: rem(14);
			font-style: normal;
			font-weight: 400;
			line-height: rem(22);
			border: 0;
		}
	}

	&__block {
		&-header, &-content {
			display: flex;
			justify-content: space-between;

			.table {
				&__header, &__content {
					&-tr {
						display: flex;
						justify-content: space-between;
						width: 100%;
					}
				}

				&__header {
					&-th {
						width: 100%;
					}
				}

				&__content {
					&-tr {
						& > div {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}
		}
	}
}

.name--content__text {
	display: flex;
	justify-content: center;
}
