.promo-code__item {
    padding: rem(30);

    &__btn {
        .btn {
            margin-left: auto;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: rem(10);

        &__info {
            display: flex;
            align-items: center;
            margin-top: rem(29);

            &__meta {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                border-radius: rem(4);
                background: rgba(255, 255, 255, 0.05);
                padding-left: rem(20);

                span {
                    max-width: rem(127);
                    width: 100%;
                    border-radius: 30px 4px 4px 10px;
                    display: flex;
                    justify-content: center;
                    padding: rem(14);
                    background: linear-gradient(90deg, #4A80EE 0%, #1F48A5 100.93%);
                    font-size: rem(20);
                    line-height: rem(22);
                    color: $white;
                }

                &__text {
                    display: flex;
                    align-items: center;
                    gap: rem(10);

                    div {
                        font-size: rem(12);
                        padding: rem(2) rem(8);
                        border-radius: rem(4);
                    }

                    p {
                        font-size: rem(14);
                        color: $white;
                        line-height: rem(22);
                    }


                }
            }

            &__inner {
                display: flex;
                align-items: center;
                gap: rem(10);
                margin-left: rem(10);

                .btn + .btn {
                    margin: 0;
                    width: rem(50);
                    height: rem(50);
                    border-radius: rem(4);
                    justify-content: center;
                    background: rgba(255, 88, 88, 0.20);
                }
                .btn {
                    margin: 0;
                    width: rem(50);
                    height: rem(50);
                    border-radius: rem(4);
                    justify-content: center;
                }
            }
        }
    }
}

.create__group__promo {
    &__title {
        display: flex;
        align-items: center;
        gap: rem(10);
        margin-bottom: rem(10);

        h5 {
            font-size: rem(15);
            color: $white;
            line-height: rem(28);
        }
    }
    .toggle__item {
        display: flex;
        align-items: center;
        gap: rem(14);
    }

    &__btn {
        display: flex;
        margin-top: rem(29);
        .btn {
            width: 100%;
            justify-content: center;
            padding: rem(13) rem(20);
            border-radius: rem(4);
        }
    }
    .toggle {
        margin-bottom: rem(10);
    }
    .form-icon-right {
        font-size: rem(12);
        color: rgba(255, 255, 255, 0.50);
    }
}
