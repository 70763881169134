.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border: 1px solid rgba(255, 255, 255, 0.01);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  &__text {
    color: var(--white, #FFF);
    font-size: 15px;
    font-weight: 400;
  }
  &__btn {
    padding: 2px;
    position: relative;
    width: 37px;
    height: 19px;
    border-radius: 20px;
    transition: .2s all;
    &-span {
      border-radius: 100%;
      background-color: #FFFFFF;
      height: 13px;
      width: 13px;
      position: absolute;
    }
  }
  &-active {
    border-radius: 100%;
    background-color: #FFFFFF;
    left: 90%;
    transform: translateX(-90%);
    height: 15px;
    width: 15px;
    position: absolute;
  }
  &-isNotActive {
    width: 13px;
    height: 13px;
    position: absolute;
    left: 9%;
    transform: translateY(2%);
    border-radius: 100%;
    background-color: white;
  }
}