.stocks {
    position: relative;
    padding: rem(30) rem(15);
    height: 100%;
    color: #FFFFFF;

    &__header {
        justify-content: space-between;
        align-items: center;
        display: flex;

        &__left {
            display: flex;
            align-items: center;

        }

        &-nonActive {
            background: none !important;
            border: rem(1) solid #3582F6 !important;
            color: #3582F6;
        }

        &-active {
            color: #3582F6;
            background: var(--blue-20, rgba(53, 130, 246, 0.20)) !important;
            border: rem(1) solid #3582F6 !important;

            &:hover {
                color: #3582F6 !important;
            }
        }

        &-right {
            display: flex;
            align-items: center;
        }
    }

    &__item {
        margin-top: rem(40);
        display: flex;
        align-items: center;
        gap: rem(20);

        &-header {
            display: flex;
            align-items: center;
            gap: rem(20);
        }

        &-point {
            display: flex;
            align-items: center;
            gap: rem(5);
            padding: rem(5) rem(10);
            border-radius: rem(4);
            background: var(--20, rgba(249, 212, 72, 0.20));
            color: #F9D448;
        }

        &-title {
            font-size: rem(16);
        }

        &-desc {
            margin-top: rem(10);
            font-size: rem(14);
            line-height: rem(18);
            font-weight: 400;
            opacity: 0.75;
        }

        &-content {
            display: flex;
            flex-direction: column;
        }

        &-btns {
            margin-top: rem(30);
            display: flex;
            gap: rem(15);
        }

        &-img {
            margin-top: rem(10);
            height: rem(160);
            background: #222329;
        }

        &-delete {
            cursor: pointer;
            border-radius: rem(4);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(13) rem(20);
            background-color: #B4474E;
        }
    }
}

.edit__stocks {
    padding: rem(29);
    &__header {
        padding-bottom: rem(29);
        &__item {
            &__cover {
                display: flex;
                align-items: center;
                gap: rem(10);

                h3 {
                    font-size: rem(20);
                    line-height: rem(28);
                    color: $white;
                    font-weight: 400;
                }
            }
        }
    }

    h2 {
        font-size: rem(20);
        line-height: rem(28);
        color: $white;
    }

    &__right-content {
        .form-file {
            padding: 0;
            min-height: rem(223);
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            margin-top: rem(10);
            display: flex;
            align-items: center;

            svg {
                width: rem(56);
                height: rem(56);
            }

            span {
                font-size: rem(20);
                color: $white;
                opacity: 0.5;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: rem(10);

        &__text {
            display: flex;
            align-items: center;
            gap: rem(10);
            font-size: rem(14);
            color: $white;
            line-height: rem(22);
            margin-bottom: rem(10);
        }
    }
}
