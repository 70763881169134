.modal {
	&__container {
		position: fixed;
		inset: 0;
		background: rgba(0, 0, 0, 0.50);
		z-index: 9999;
	}

	&__block {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: none;
		background: #1E1F24;
		overflow: auto;
		border-radius: 4px;
		outline: none;
		padding: rem(30);
		max-height: 90vh;
		width: 90%;

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: rem(30);

			p {
				color: $white;
				font-size: rem(20);
				font-style: normal;
				font-weight: 400;
				line-height: rem(28);
			}
		}

		&-close {
			margin-left: auto;
			border: none;
			background-color: transparent;
		}
	}
}

.buy-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $white;

	&-icon {
		padding-bottom: rem(20);

		span {
			border-radius: rem(4);
			background: linear-gradient(90deg, #467FFF 0%, #A414FF 100%);
			padding: rem(5);
			max-width: rem(65);
			width: 100%;
		}
	}

	&-header {
		padding-bottom: rem(20);

		h4 {
			font-size: rem(20);
			line-height: rem(22);
		}
	}

	&-text {
		font-size: rem(14);
		line-height: rem(18);
		text-align: center;
		opacity: 0.5;
		margin-bottom: rem(20);
	}

	&-btn {
		width: 100%;
	}
}

.dialog {
	&__right {
		&__container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			background: rgba(0, 0, 0, 0.50);
			z-index: 99;
			margin-right: rem(-30);
		}

		&__block {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
		}
	}
}

.info-pc {
	//padding: rem(10) 0;

	&__header {
		display: flex;
		align-items: center;
		padding: rem(10) 0;

		.btn {
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.10);
			padding: rem(2) rem(8);

			&:hover {
				color: white;
			}

			&-red {
				&:hover {
					color: #FF5858;
				}
			}
		}
	}

	&__content {}

	&__block {
		padding: rem(15) 0;
		color: $white;

		p {
			font-size: rem(16);
			line-height: rem(22);
		}

		p + p {
			margin-top: rem(5);
		}
	}
}
