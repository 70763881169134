@import "./vars/variables";
@import "./vars/functions";

@for $i from 1 through 60 {
    @media (min-width: 1800px + ($i * 100)) {
        html {
            font-size: #{$browser-context + $i}px;
        }
    }
}

@media (max-width: 1460px) {
	.computers__sale__center__block__discount__btn {
		div, button {
			width: 100%;
		}

		.btn {
			margin-top: rem(20);
		}
	}
}

@media (max-width: 1024px) {
    .computers__sale {
        &__center {
            &__block {
                &__discount {
                    &__btn {
                        flex-direction: column;
                        gap: rem(10);
                    }
                }
            }
        }
    }

    .achievements {
        &__item {
            &-btns {
                margin-top: auto;
                padding-top: rem(20);

                .btn {
                    justify-content: center;
                }
            }
        }

        &__header {
            &-left {
                flex-wrap: wrap;

                .btn:last-child {
                    margin-left: rem(0);
                    width: 97.6%;
                    margin-top: rem(15);
                    justify-content: center;
                }
            }

            &-right {
                margin-bottom: auto;

                .btn {
                    white-space: pre;
                }
            }
        }
    }

    .dashboard {
        .challenge-info__item {
            &__blocks {
                &__control {
                    flex-direction: column;
                    gap: rem(15);
                }

                h4 {
                    font-size: rem(12.2);
                }
            }
        }
    }

    .edit__person__select {
        max-width: rem(307);
        width: 100%;
    }

    .edit__block {
        &__item {
            &__search {
                &__phone {
                    width: 100%;
                }

                input {
                    font-size: rem(12);
                }
            }
        }
    }

    .edit__person__select {
        &__switch {
            .tariffsAdd__template {
                flex-direction: row;
                gap: rem(30);
                align-items: center;
            }
        }
    }

    .users__user {
        &-footer {
            display: block;
            padding-top: rem(20);
        }
    }

    .news {
        &__content {
            .achievements__item {
                &-btns {
                    flex-direction: column;

                    .btn {
                        justify-content: center;
                    }
                }
            }
        }

        &__header {
            flex-wrap: wrap;
            gap: rem(15);

            &__left {
                width: 100%;

                .btn {
                    width: 100%;
                    justify-content: center;
                }
            }

            &-right {
                width: 100%;

                .btn {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }

    .tournaments__grid {
        .news__header-right {
            width: auto;
        }

        .news__header__left {
            width: auto;
        }
    }

    .game {
        &__header {
            flex-direction: column;
            gap: rem(15);

            &__left {
                .btn {
                    max-width: 100%;
                }
            }
        }
    }

    .reports {
        &__filter {
            flex-wrap: nowrap;

            &-selection {
                max-width: rem(262);
                width: 100%;
            }

            &-buttons {
                width: 100%;
            }
        }

        &__occupancy {
            &-header {
                flex-direction: column;
                gap: rem(15);
            }

            &-btns {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .map-center {
        &__content {
            justify-content: center;

			.map-center {
				&__block, &__items {
					width: 100%;
				}

				&__console__selected {
					max-width: 100%;
				}
			}
        }
    }

	.club {
		&__add {
			&__content {
				.dropdown__block {
					max-width: 100%;
				}
			}
		}
	}
}

@media (max-width: 767px) {
    .questions-edit {
        .achievements__edit-content {
            padding-bottom: rem(30);
        }
    }

    .logs {
        padding-bottom: rem(50);

        &-style {
            padding-bottom: rem(50);
        }
    }

    .achievements {
        padding-bottom: rem(50);

        &__item-img {
            min-height: auto;
        }

        &__item {
            &-header {
                justify-content: space-between;
            }
        }
    }

    .admin-layout {
        &-row {
            height: auto;
            flex-direction: column-reverse;
        }
    }

    .map-center {
        &__content {
            padding-bottom: rem(50);
            justify-content: space-between;
        }
    }

    .create {
        &__group {
            &__promo {
                &__btn {
                    margin-top: rem(0);
                    padding: rem(30) rem(0);
                }
            }
        }
    }

    .achievements {
        &__header {
            justify-content: center;

            &-left {
                width: 100%;
                gap: rem(10);

                .btn {
                    width: 100%;
                    justify-content: center;
                    margin: 0;
                }

                .btn:last-child {
                    margin-top: rem(0);
                    width: 100%;
                }
            }

            &-right {
                position: fixed;
                z-index: 4;
                bottom: 100px;
            }
        }

        &__bottom {
            padding-bottom: rem(56);
        }

        &__content {
            padding-bottom: rem(56);
        }
    }

    .open {
        position: fixed;
        background: #222329;
        z-index: 9998;
        top: 0;
        transform: translateY(0);
        transition: 0.7s ease-in-out;
        height: calc(100% + -70px);
        overflow: auto;
    }

    .hide {

    }

    .sidebar__list {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: rem(30) rem(0);
        height: auto;

        &-exit {
            padding: rem(0) rem(20);
            display: block;

        }

        .header-menu {
            display: block;
        }

        .dividers {
            display: block;
        }

        .admin-sidebar {
            padding: rem(0);
        }


    }

    .program__block__item {
        padding-bottom: rem(50);
        padding-top: rem(20);

        &__list {
            padding-bottom: rem(40);
        }
    }

    .discounts__block {
        height: 100vh;

        .discounts__item {
            padding: rem(20) rem(10) rem(20) rem(10);

            &__block {
                padding: rem(0);

                &__meta {
                    padding-left: rem(10);
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: rem(11);
                padding: rem(48) rem(0) rem(56) rem(0);
            }
        }
    }

    .promo-code {
        height: 100%;

        &__item {
            padding: rem(20) rem(10);

            &__list {
                gap: rem(11);
                padding-top: rem(48);

                &__info {
                    margin: rem(0);

                    &__meta {
                        padding-left: rem(14);
                    }
                }
            }

            &__btn {
                display: flex;
                justify-content: flex-end;
                padding-bottom: rem(15);

                .btn {
                    margin: rem(0);
                    justify-content: center;
                }
            }
        }
    }

    .create__group {
        padding-bottom: rem(50);
    }

    .computers {
        padding-bottom: rem(56);

        &-page {
            padding-bottom: rem(50);
        }

        &-style {
            padding-bottom: rem(50);
        }

        .dialog__right__container {
            width: 100%;
            min-height: rem(753);

            .dialog__right__block {
                width: 100% !important;
                padding: rem(0) rem(20) !important;

                .modal__block-header {
                    padding-top: rem(38);
                    padding-bottom: rem(0);

                    p {
                        width: 100%;
                        text-align: center;
                    }
                }

                .info-pc {
                    &__header {
                        justify-content: center;
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: rem(20);
                    }

                    &__block {
                        padding: rem(0);
                    }
                }
            }
        }
    }

    .aside {
        display: none;
    }

    .header {
        display: none;
    }

    .content {
        padding: 0;
    }


    .dashboard {
        padding: rem(30) rem(5) rem(56) rem(5);

        .challenge-info__item {
            background: none;
            padding: rem(0) rem(0) rem(30) rem(0);

            &__add {
                flex-direction: column;
                gap: rem(15);
                overflow: auto;
                margin-bottom: rem(20);

                .table {
                    margin: rem(0);
                }
            }

            &__blocks {
                &__control {
                    flex-direction: row;
                }
            }

            .tabs-list_content .react-tabs__tab--selected {
                border-radius: rem(100);
                background: (linear-gradient(90deg, #7F67D3 0%, #B35DEE 100%));
                padding: rem(8) rem(12);
            }

            .react-tabs__tab-panel {
                background: rgba(255, 255, 255, 0.05);
            }
        }
    }

    .discipline {
        &__add {
            padding-bottom: rem(30);
            padding-top: rem(20);

            &__list {
                padding-bottom: rem(56);
                justify-content: center;
            }
        }
    }

    .mobile__block {
        display: block;

        &__menu {
            white-space: pre;
        }
    }

    .person__block {
        padding: rem(30) rem(20) rem(56) rem(20);
    }

    .booking {
        height: 100%;

        &__header__back {
            display: block;
            margin-top: rem(15);
        }

        &__blocks-btn {
            margin-top: rem(20);
        }
    }

    .users__user-back {
        display: block;

        .btn {
            width: 100%;
            border: none;
            outline: none;
            background: none;
            color: $white;

            .header__text {
                width: 100%;
                text-align: center;
            }
        }
    }

    .edit__person__select {
        max-width: 100%;
    }

    .users__user {

        &-footer {
            padding-bottom: rem(40);
        }
    }

    .program__block__item {
        &__appellation {
            height: 100%;
            padding-top: rem(30);

            p {
                align-items: flex-end;
            }
        }
    }

    .news {
        &__header {
            justify-content: center;

            &__left {
                width: 100%;
                padding-top: rem(20);


                .btn {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        &__content {
            .achievements__item-btns {
                flex-direction: row;
            }

            padding-bottom: rem(56);
        }
    }

    .edit__news {
        .edit__block__item__edit {
            padding-bottom: rem(38);
        }
    }

    .game__list__header {
        padding-bottom: rem(70);

        &__button {
            padding-bottom: rem(40);
        }
    }

    .achievements__edit {
        &-button {
            padding-bottom: rem(38);
        }
    }

    .tournament {
        &__edit {
            &__inner {
                &__right {
                    justify-content: center;
                }
            }
        }
    }

    .game {
        &__content {
            padding-bottom: rem(80);
        }
    }

    .edit__tournament {
        padding: rem(15) rem(5) rem(30) rem(5);

        &__list {
            padding-bottom: rem(56);

            &-page {
                flex-wrap: wrap;
            }
        }
    }

    .tournament__edit {
        padding: rem(20) rem(20) rem(40) rem(20);

        &-content {
            padding-bottom: rem(56);

            &__btn {
                z-index: 4;
            }
        }
    }

    .tournaments__grid {

        .news__header {
            justify-content: space-between;

            &-right {
                position: relative;
                padding: rem(0);
            }

            &__left {
                padding: rem(0);
            }
        }

        .computers__content {
            margin: rem(15) rem(0);
        }
    }

    .logs {
        &__content-items {
            overflow: auto;
        }

        &__item {
            min-width: rem(570);
        }
    }

    .users__edit {
        padding-left: rem(0);

        .users__user {
            padding-bottom: rem(40);
        }
    }

    .reports {
        &-style {
            padding-bottom: rem(50);
        }

        &__shift {
            padding-bottom: rem(30);
        }

        &__user {
            &-header {
                .form-group {
                    margin-bottom: rem(0);
                }
            }

            &-dates {
                padding-right: rem(10);
            }
        }
    }

    .edit__block {
        .achievements__edit-form {
            padding-bottom: rem(30);
        }
    }

    .added__booking__dialog {
        .search__block {
            &-dialog {
                padding-bottom: rem(50);
            }
        }
    }

	.club__add__content-btn {
		display: none;

		&-mobile {
			display: flex;
		}
	}
}

@media (max-width: 480px) {
    .season__wrap {
        padding-bottom: rem(50);

        &-main {
            padding-bottom: rem(55);
        }
    }

    .messenger {
        overflow: hidden;
    }

    .edit__block__item__back {
        width: 100%;

        h2 {
            width: 100%;
            text-align: center;
        }
    }

    .tariffs {
        gap: rem(16);
        padding-top: rem(20);

        &__content {
            padding: rem(0) rem(0) rem(80) rem(0);
            justify-content: center;
        }

        &__header {
            &__inner {
                position: fixed;
                z-index: 4;
                bottom: 100px;
                width: 100%;
                left: 0;
                padding: rem(0) rem(30);

                .btn {
                    margin-top: rem(0);
                    width: 100%;
                }
            }
        }

        &-edit__btns-btn {
            position: fixed;
            bottom: 100px;
            z-index: 4;
            left: 0;
            padding: rem(0) rem(40);
        }

        &-style {
            margin-top: rem(0);
        }

        &-inner {
            padding-bottom: rem(60);
        }

        &-edit {
            padding-bottom: rem(30);
        }
    }

    .news {
        &__content {
            .achievements__item {
                &-btns {
                    flex-direction: column;
                }
            }
        }

        &__header {
            &-right {
                .achievements__header-nonActive {
                    background: #1E1F24 !important;
                }
            }
        }
    }

    .achievements {
        &__item {
            &-content {
                width: 100%;
            }

            &-img {
                max-width: 100%;
            }

            &-btns {
                flex-direction: column;
            }
        }
    }

    .program__block__item {
        padding-bottom: rem(70);

        &__list {
            padding: rem(0) rem(5) rem(80) rem(5);

            &__image {
                width: 100%;

                &__btn {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        &__header {
            padding: rem(16) rem(20) rem(20) rem(20);

            .btn {
                max-width: 100%;
            }

            &-last {
                position: fixed;
                z-index: 4;
                left: 0;
                bottom: 100px;
                max-width: 100%;
                padding: rem(0) rem(20);
            }
        }
    }

    .discounts__block {
        .discounts__item {
            &__list {
                padding: rem(16) rem(0) rem(5) rem(0);
            }
        }
    }

    .pages-comp {
        padding-bottom: rem(100) !important;
    }

    .map-center {
        &__items {
            max-width: 100%;
        }

        &-main {
            padding-bottom: rem(50);
        }

        &-section {
            padding-bottom: rem(50);
        }

		&__console__selected {
			max-width: 100%;
		}
    }

    .dashboard {
        h4 {
            font-size: rem(18);
        }

        &__bg {
            &__section {
                span {
                    font-size: rem(18);
                }
            }
        }
    }

    .column {
        flex-direction: column-reverse;
    }

    .map-center {
        &__content {
            padding-bottom: rem(50);
            justify-content: center;
        }
    }

    .reports {
        &__occupancy {
            &-btn {
                width: 100%;
                justify-content: space-between;
            }

            &-content {
                overflow: auto;
            }

            .chart {
                &__bottom {
                    &-item {
                        padding-right: rem(10);
                    }
                }
            }

            p {
                white-space: pre;
            }

            &-subheader {
                gap: rem(10);
            }
        }

        &__user {
            &-dates {
                flex-direction: column;
                width: 100%;
                padding-bottom: rem(10);
                padding-right: rem(0);

                .custom-calendar {
                    width: 100%;
                }

                .btn {
                    width: 100%;
                    justify-content: center;
                }
            }

            &-header {
                flex-wrap: wrap;

                .form-group {
                    width: 100%;
                }
            }
        }

        &-date {
            min-width: auto;
        }

        &__filter {
            flex-direction: column;

            &-buttons {
                gap: rem(10);

                .btn {
                    width: 100%;
                }
            }

            .person__block {
                &__wrapper {
                    padding: rem(0) rem(10);
                }
            }

            &-line {
                display: none;
            }
        }

        .edit__block {
            padding-bottom: rem(56);

            .container-fluid {
                padding-bottom: rem(20);
            }

            &-date {
                width: 100%;
            }
        }

        &-style {
            padding: rem(16) rem(0) rem(20) rem(0);
        }
    }

    .questions-edit {
        padding: rem(20) rem(5) rem(30) rem(5);
    }

    .computers {
        &__sale {
            overflow: auto;
        }
    }
}

@media (max-width: 465px) {
    .login-page {
        position: relative;
    }
    .sign {
        &-in {
            &-form {
                margin-top: rem(50);
                justify-content: space-between;
                height: 60%;
                padding: rem(20);
                background: none;

                .game__header-right {
                    max-width: unset;
                    bottom: rem(50);
                }
            }
        }
    }
    .club {
        &__add {
            &__header {
                display: flex;
                align-items: center;
                &__title {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        &__header {

            &-block {
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    .clubs {
        &__header {
            &__back {
                display: flex;
                align-items: center;

                h4 {
                    width: 100%;
                }
            }
        }
    }
	.added__booking__dialog {
		padding-bottom: rem(30);
	}

	.edit__tournament {
		padding-bottom: rem(70);

		&__list {
			padding: rem(0);
		}

		&__info {
			&__items {
				display: block;

				&__left {
					max-width: none;
				}

				&__header {
					padding-left: rem(0);
					padding-top: rem(20);

					&__meta {
						flex-wrap: wrap;
					}

					&__map {
						flex-wrap: wrap;
						gap: rem(5);

						&__list {
							.line__right {
								margin: rem(0) rem(5);
							}

							p {
								font-size: rem(12);
							}
						}
					}
				}
			}

			&__btn {
				flex-direction: column;
				gap: rem(10);

				.btn + .btn {
					width: 100%;
					margin: rem(0);
				}
			}
		}
	}

	.tournament__edit {
		padding: rem(20) rem(5) rem(20) rem(5);

		&-style {
			padding-bottom: rem(40);
		}

		&-main {
			padding-bottom: rem(50);
		}

		&-content {
			&__btn {
				position: fixed;
				bottom: 100px;
				left: 0;
				z-index: 4;
				padding: rem(0) rem(20);
			}
		}

		&-page {
			padding-bottom: rem(40);
		}

		.form-file {
			min-height: rem(223);
		}
	}

	.edit__news {
		padding: rem(20) rem(5) rem(50) rem(5);

		&-main {
			padding-bottom: rem(58);
		}

		&__header {
			&__item {
				&__cover {
                    h3 {
                        font-size: rem(14);
                    }
				}
			}
		}

		&-style {
			padding-bottom: rem(56);
		}

		.edit__block__item__edit {
			position: fixed;
			bottom: 100px;
			left: 0;
			z-index: 4;
			padding: rem(0) rem(20);
			margin-top: rem(0);
			width: 100%;

			.btn {
				margin: rem(0);
			}

			.achievements__edit-button {
				margin: rem(0);
				padding: rem(0);
			}
		}

		&__right {
			&-content {
				.form-file {
					min-height: rem(187);

					.file {
						margin: rem(0);
					}
				}
			}
		}
	}

	.program__block__item {
		height: 100%;
		padding-bottom: rem(70);

        &__list {
            &__image {
                width: 50%;

                img {
                    min-height: rem(160);
                }
            }
        }

		&__header {
			&-last {
				position: fixed;
				bottom: 100px;
				left: 0;
				max-width: none;
				padding: rem(0) rem(20);

				.btn {
					max-width: none;
					width: 100%;
				}
			}

			.btn {
				max-width: none;
			}
		}
	}

	.person {
        &__items {
            &__block {
                padding: rem(20);

                svg {
                    width: rem(13);
                    height: rem(13);
                }
            }
        }
		&__block {
			padding: rem(20) rem(10);

			&__wrapper {
				padding-top: rem(16);

				.person_btn__item {
					position: fixed;
					bottom: 100px;
					z-index: 4;
					left: 0;
					max-width: 100%;
					padding: rem(0) rem(0);
				}
			}

			&-form {
				max-width: 100%;
			}
		}

		&-btn {
			&__button {
				display: block;
				position: fixed;
				bottom: rem(100);
				left: rem(20);
				right: rem(20);
			}
		}
	}

	.edit {
		&__block {
			padding: rem(20) rem(5);
		}

		&__person__select {
			max-width: 100%;
			//padding: rem(15);

			.react-select-prefix__value-container {
				font-size: rem(14);
			}

			&__switch {
				&__inclusion {
					width: 100%;
				}
			}
		}
	}

	.edit__block {
		&__item {
			&__search {
				&__phone {
					display: none;
				}
			}
		}
	}

	.style-edit {
		padding-bottom: rem(105);

		.edit-style {
			position: fixed;
			bottom: 100px;
			z-index: 4;
			width: 100%;
			left: 0;
			padding: rem(0) rem(20);
			margin: rem(0)
		}

		.edit__block__item__header {
			margin: rem(0);

			.person__popup {
				padding: rem(0);
			}
		}
	}

	.edit__block {
		.edit__block__item {
			padding: rem(0);
			margin-top: rem(20);

			&__search {
				&__phone {
					display: none;
				}

				input {
					font-size: rem(14);
				}

				.react-select-prefix__value-container {
					font-size: rem(14);
				}
			}

			form {
				max-width: 100%;
				width: 100%;
			}

			.form-group {
				max-width: 100%;
			}

			&__edit {
				gap: rem(10);
			}

			&__btn {
				max-width: 100%;
			}
		}

		.edit__person__select {
			padding-bottom: rem(65);
		}
	}

	.person__block {
		padding-bottom: rem(105);

		&-style {
			padding-bottom: rem(60);
		}

		&__wrapper {
			.btn__item {
				.person_btn__item {
					position: fixed;
					bottom: 100px;
					width: 100%;
					left: 0;
					padding: rem(0) rem(20);
					z-index: 4;
				}
			}
		}
	}

	//.layout-style {
	//    padding-bottom: rem(56);
	//}

	.computers {
		padding: rem(20) rem(5) rem(0) rem(5);
		overflow: hidden;

        &__sale {
            overflow: unset;
            &__center {
                flex-direction: column;

                &__block {
                    min-height: unset;
                }

                &__info {
                    &__bottom {
                        overflow-y: scroll;
                        min-height: unset;
                    }

                    &__tariff {
                        &__middle {
                            color: #ffffff;
                            border-radius: rem(100);
                            background:linear-gradient(90deg, #7F67D3 0%, #B35DEE 100.93%);

                            .btn.btn-link {
                                border-width: 0;
                                color: #ffffff;
                                border-radius: rem(100) !important;
                                min-height: unset;
                            }
                        }
                    }
                }

                &__right {
                    flex-direction: column;
                    flex-wrap: unset;
                    max-width: unset;

                    &__check {
                        max-height: unset;
                        min-height: rem(200);
                    }
                }
            }
        }

		&-main {
			padding-bottom: rem(80);
		}

		&-mains {
			padding-bottom: rem(55);
		}

		&-page {
			padding-bottom: rem(70);
		}

		&__content {
			margin-top: rem(16);
		}

		&-style {
			padding-bottom: rem(80);
		}

		&__header {
			flex-direction: column;
			width: 100%;

			&-left {
				width: 100%;
				max-width: 100%;
				margin-top: rem(16);

				.form-group {
					width: 100%;
					margin: rem(0);
				}

                .active {
                    background:  linear-gradient(90deg, #7F67D3 0%, #B35DEE 100.93%) !important;
                }

                .false {
                    background: rgba(255, 255, 255, 0.05) !important;
                }

				.btn {
					width: auto;
					min-height: auto;
					padding: 0 !important;
					box-shadow: none;
					border-radius: rem(100);
					color: $white !important;
					//background: rgba(255, 255, 255, 0.05);
					border: none;

					.header__text {
						padding: rem(8) rem(12);
					}
				}

				.btn:focus {
					background: linear-gradient(90deg, #7F67D3 0%, #B35DEE 100.93%);
				}
			}

			&-right {
				position: fixed;
				left: 0;
				bottom: rem(100);
				width: 100%;
				z-index: 4;
				flex-direction: column;
				gap: rem(10);
				padding: rem(0) rem(20);

				.btn {
					width: 100%;
					justify-content: center;
					margin: 0;

					&-link {
						background: #1E1F24;

						&:hover {
							background: #1E1F24;
						}
					}
				}
			}

			.computers__header-right {
				.btn {
					&-link {
						&:hover {
							background: #1E1F24;
						}
					}
				}
			}

		}

		&__right-mobile {
			display: block;

			.btn {
				width: 100%;
				margin: rem(0);
				border: 1px solid #3582F6;
			}

			.btn + .btn {
				margin-top: rem(10);
				justify-content: center;
				border: none;
			}
		}

		.dialog__right__block {
			min-height: rem(780);

			.modal__block-header {
				padding: rem(20) rem(0) rem(0) rem(0);
			}
		}

		.dialog__right__container {
			min-height: auto;
		}

		&__mobile-meta {
			display: block;
		}

		&__dialog__all-groups {
			.btn {
				width: 100%;
			}
		}
	}

	.booking {
		padding: rem(20) rem(20) rem(56) rem(20);

		&__header {
			display: block;
			text-align: center;
		}

		&__blocks {
			&-btn {
				flex-direction: column;
				margin-top: rem(16);

				&__new {
					position: fixed;
					z-index: 3;
					bottom: 100px;
					width: 100%;
					padding: rem(0) rem(20);

					.btn {
						width: 100%;
						justify-content: center;
					}
				}
			}
		}

		&__block {
			flex-direction: column;
			width: 100%;
			gap: rem(10);
		}

		&__type {
			width: 100%;
			margin: 0;

			.react-select-prefix__control {
				max-width: 100%;
			}
		}

		&__datepicker {
			width: 100%;
		}

		&__mobile-meta {
			display: block;

			&-back {
				width: 100%;
				justify-content: center;
			}
		}
	}

	.added__booking__dialog {
		&-setting {
			&-date {
				flex-direction: column;
				gap: rem(15);

				.custom-calendar {
					width: 100%;
				}

				&-time {
					width: 100%;
					max-width: 100%;
					margin: rem(0);
				}
			}
		}
	}

	.users__user-back {
		.btn {
			width: 100%;
			border: none;
			background: none;
			outline: none;
			color: $white;
			gap: rem(0) !important;

			.header__text {
				width: 100%;
				text-align: center;
				display: block;
			}
		}
	}

	.users__edit {
		padding: rem(20) rem(5) rem(60) rem(5);

		&-main {
			padding-bottom: rem(50);
		}
	}

	.editors {
		padding: rem(0);
	}

	.users__user {
		padding-bottom: rem(50) !important;

		&-content {
			&-game {
				display: block;

				.btn {
					max-width: 100%;
				}
			}
		}

		&-header {
			&-btn {
				position: fixed;
				bottom: 100px;
				z-index: 4;
				width: 100%;
				padding: rem(0) rem(20);
				left: 0;

				.btn {
					max-width: 100%;
				}
			}
		}

		&-footer {
			padding-bottom: rem(5);

			.btn {
				max-width: 100%;
			}
		}
	}

	.promo-code {
		&-main {
			padding-bottom: rem(50);
		}

		&__item {
			padding: rem(20) rem(5) rem(56) rem(5);

			&__list {
				padding-bottom: rem(5);
				padding-top: rem(16);

				&__info {
					flex-direction: column;
					gap: rem(10);

					&__inner {
						margin: rem(0);
						width: 100%;

						.btn {
							width: 100%;
						}

						.btn + .btn {
							width: 100%;
						}
					}
				}
			}

			&__btn {
				position: fixed;
				left: 0;
				width: 100%;
				justify-content: center;
				bottom: 100px;
				padding: rem(0) rem(20);
				z-index: 4;

				.btn {
					width: 100%;
				}
			}
		}

		&-page {
			padding-bottom: rem(56);
		}
	}

	.news {
		padding: rem(20) rem(5) rem(56) rem(5);

		&-main {
			padding-bottom: rem(40);
		}

		&-meta {
			padding-bottom: rem(40);
		}

		&__header {
			&__left {
				flex-direction: column;
				gap: rem(10);
				padding-top: rem(16);

				.btn {
					margin: rem(0);
				}
			}

			&-right {
				position: fixed;
				left: 0;
				bottom: 100px;
				padding: rem(0) rem(20);
				flex-direction: column;
				gap: rem(11);
				z-index: 4;

				.btn {
					margin: rem(0);
				}
			}

			&-nonActive {
				white-space: pre;
			}
		}

		&__bottom {
			padding-bottom: rem(56);
		}

		.styles {
			padding-bottom: rem(56);
		}
	}

    .modal__block {
            padding: rem(20);
    }


	.achievements {
		padding: rem(20) rem(5);

		&-main {
			padding-bottom: rem(40);
		}

		&__header {
			&-left {
				padding-top: rem(16);
			}

            &-active {
                color: #FFFFFF;
                border-width: 0 !important;
                border-radius: 100px !important;
                background: linear-gradient(90deg, #7F67D3 0%, #B35DEE 100.93%) !important;
                padding: 8px 12px;

                &:hover {
                    border-width: 0 !important;
                    color: #FFFFFF !important;
                }
            }

            &-nonActive {
                color: #FFFFFF;
                border-width: 0 !important;
                border-radius: 100px !important;
                background: rgba(255, 255, 255, 0.05) !important;
                padding: 8px 12px;
            }

			&-right {
				width: 100%;
				padding: rem(0) rem(20);

				.btn {
					width: 100%;
					justify-content: center;
				}
			}
		}

		&__item {

			&-point {
				margin-left: auto;
			}

			&-header {
				gap: rem(0);
			}

			&-btns {
				justify-content: space-between;
			}
		}
	}

	.edit__stocks {
		padding: rem(20) rem(5) rem(56) rem(5);

        &__item {
            &__header {
                width: 100%;
            }

            h2 {
                width: 100%;
                text-align: center;
            }
        }

		&-main {
			padding-bottom: rem(50);
		}

		&-content {
			padding-bottom: rem(46);

			.edit__block__item__edit {
				position: fixed;
				margin: rem(0);
				bottom: 100px;
				width: 100%;
				left: 0;
				padding: rem(0) rem(20);

				.achievements__edit-button {
					padding: rem(0);
					margin: rem(0);
				}
			}
		}

		&__header {
			&__item {
				&__cover {
                    h3 {
                        font-size: rem(14);
                    }
				}
			}
		}

		&-style {
			padding-bottom: rem(50);
		}

		&__right {
			&-content {
				.form-file {
					min-height: rem(187);
				}
			}
		}
	}

	.game__list__header {
		padding: rem(20) rem(20) rem(56) rem(20);

        &__back {
            width: 100%;
            display: flex;
            align-items: center;

            h3 {
                width: 100%;
                text-align: center;
            }
        }

		&-main {
			padding-bottom: rem(56);
		}

		&__button {
			position: fixed;
			bottom: 100px;
			z-index: 4;
			left: 0;
			padding: rem(0) rem(20);
			width: 100%;
			gap: rem(10);

			.btn {
				margin: rem(0);
				min-height: auto;
			}
		}

		&__show {
			padding-bottom: rem(56);

			.edit__news__header__item__cover {
				margin-bottom: rem(30);
			}
		}

		.game-style {
			padding-top: rem(16);
		}
	}

	.achievements__edit {
		padding: rem(20) rem(5);

		&-main {
			padding-bottom: rem(56);
		}

		.discipline__content__form__right__file {
			.tournament__edit__inner__right {
				justify-content: center;
			}
		}

		.edit__block__item__edit {
			position: fixed;
			bottom: 100px;
			z-index: 4;
			width: 100%;
			left: 0;
			margin-top: rem(0);

			.achievements__edit-button {
				padding: rem(0) rem(20);
				margin: rem(0);

				.btn {
					width: 100%;
					justify-content: center;
				}
			}
		}

		&-style {
			padding-bottom: rem(30);
		}

		&-content {
			padding-bottom: rem(56);
		}

		&-form {
			padding-bottom: rem(37);
		}
	}

	.game {
		padding: rem(20) rem(20) rem(56) rem(20);

		&-main {
			padding-bottom: rem(30);
		}

		&__header {
			flex-direction: column;
			padding-top: rem(16);

			&-right {
				position: fixed;
				bottom: 100px;
				width: 100%;
				z-index: 4;
				left: 0;
				padding: rem(0) rem(20);

				.btn {
					white-space: normal;
				}
			}

			&__left {
				gap: rem(10);
                margin-bottom: rem(20);

				.btn {
                    width: unset;
					margin: rem(0);
				}
			}
		}

		&__content {
			&__pro {
				width: 50%;
				padding-bottom: rem(40);

				&__item {
					width: 100%;
					margin-top: rem(0);
					max-width: none;
					min-width: inherit;

					img {
						max-width: 100%;
						max-height: none;
						width: 100%;
					}

					&__hour {
						width: 100%;
					}
				}
			}
		}
	}

	.discounts__item {
		padding: rem(20) rem(5) rem(56) rem(5) !important;

		&__button {
			position: fixed;
			width: 100%;
			bottom: 100px;
			padding: rem(0) rem(20);
			left: 0;
			justify-content: center;
			z-index: 4;

			.btn {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	.discounts__block {
		height: 100%;

		&-main {
			padding-bottom: rem(50);
		}

		&-page {
			padding-bottom: rem(56);
		}
	}

	.clubs {
		padding: rem(20) rem(5);

		.club__content {
			justify-content: center;

			.club__block {
				max-width: 100%;
			}

			.btn-add-club {
				min-height: auto;
				max-width: 100%;
			}
		}

		h4 {
			text-align: center;
		}

		.club__add__header {
			padding-bottom: rem(16);
		}
	}

	.tournaments__grid {
		padding: rem(20) rem(20) rem(56) rem(20);

		&__tour {
			&__block {
				.tournaments__grid__tour__container {
					padding-right: rem(10);

					&__winner {
						padding: rem(6.2);

						div {
							white-space: pre;
						}
					}
				}


			}
		}

		.news__header {

			&-right {
				position: fixed;
				bottom: 100px;
				z-index: 4;
				width: 100%;
				left: 0;
				padding: rem(0) rem(20);
			}
		}
	}

	.discipline {

		&-main {
			padding-bottom: rem(56);
		}

		&__add {
			padding-bottom: rem(20);

			&__btn {
				position: fixed;
				bottom: 100px;
				z-index: 4;
				left: 0;
				width: 100%;
				padding: rem(0) rem(20);
				justify-content: center;

				.btn {
					max-width: 100%;
				}
			}

			&__list {
				padding: rem(16) rem(5) rem(56) rem(5);
			}
		}

		&__content {
			padding: rem(20);

			&__form {
				flex-direction: column-reverse;
				padding-bottom: rem(25);

				&__right {
					max-width: 100%;

					.form-file {
						max-width: 100%;
					}

				}

				&__left {
					padding-bottom: rem(56);
				}
			}
		}
	}

	.messenger__right {
		background: #1E1F24;
	}

	.messenger-chat {
		padding-bottom: rem(95);
	}

	.messenger__left {
		padding-bottom: rem(100) !important;
	}

	.messenger__left,
	.messenger__right {
		transition: transform 0.3s ease;
	}

	.messenger__left-active .messenger__left {
		transform: translateX(0%);
	}

	.messenger__left-active .messenger__right {
		transform: translateX(-100%);
	}

	/* Стили для messenger__right, когда активен */
	.messenger__right-active .messenger__right {
		transform: translateY(-100%);
	}

	.messenger__right-active .messenger__left {
		transform: translateX(0%);
	}

	.messenger-chat__response {
		max-width: none;
		width: 100%;
	}

	.achievements {
		padding: rem(20) rem(5);

		&__item {
			gap: rem(0);

			&-point {
				margin-left: auto;
				width: auto !important;
				flex-direction: row !important;
			}

			&-header {
				flex-direction: row !important;
			}
		}

		&__content {
			div {
				width: 100%;
				flex-direction: column;
			}
		}
	}
}

@media (max-width: 430px) {
	.questions {
		padding: rem(20);

		&-style {
			padding-top: rem(16);
			padding-bottom: rem(50);
		}
	}

	.reports {
		padding: rem(20) rem(15) rem(30) rem(15);

		&-main {
			padding-bottom: rem(50);
		}

		&__shift {
			padding-bottom: rem(0);
		}
	}

	.questions-edit {
		.achievements__edit-content {
			padding-bottom: rem(0);

			.achievements__edit-button {
				padding-bottom: rem(15);
			}
		}
	}

	.logs {
		padding: rem(20) rem(5) rem(50) rem(5);

		&-style {
			padding: rem(16) rem(15) rem(40) rem(15);
		}

		&__header {
			flex-direction: column;
			gap: rem(10);

			&-search {
				width: 100%;
			}

			&-select {
				width: 100%;
			}

			.notification__header-btn {
				width: 100%;

				.btn {
					width: 100%;
					justify-content: center;
				}
			}
		}
	}

	.dashboard {
		padding-bottom: rem(50);
		padding-top: rem(20);

		h4 {
			font-size: rem(16);
		}

		&__header__subject {
			h2 {
				font-size: rem(20);
				line-height: rem(28);
				color: #FFFFFF;
				margin-bottom: rem(16);
				text-align: center;
			}
		}
	}

	.edit__block {
		padding: rem(20) rem(5) rem(56) rem(5);
	}

	.settings {
		padding: rem(20);
		gap: rem(16);
	}

	.program__block__item {
		&__appellation {
			p {
				height: calc(100vh - 600px);
				align-items: center;
			}
		}
	}

	.club__add__content {
		.reports__filter-selection {
			max-width: none;
		}
	}

	.added__booking__dialog {
		.search__block-dialog {
			padding-bottom: rem(0);
		}
	}

	.block-enter {
		opacity: 0;
		transform: translateY(100%);
	}

	.block-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 700ms, transform 700ms;
	}

	.block-exit {
		opacity: 1;
		transform: translateY(0);
	}

	.block-exit-active {
		opacity: 0;
		transform: translateY(100%);
		transition: opacity 700ms, transform 700ms;
	}

	.block-exit-done {
		opacity: 0;
		transform: translateY(100%);
	}

	.popup__container {
		left: 0 !important;
	}

}

@media (max-width: 400px) {
	.dashboard {
		h4 {
			font-size: rem(12);
		}
	}
}

@media (max-width: 376px) {
	.tournaments__grid {
		padding-bottom: rem(75);
	}

	.promo-code {
		&__item {
			&__list {
				&__info {
					&__meta {
						span {
							max-width: rem(90);
						}
					}
				}
			}
		}
	}

	.discounts {
		&__item {
			&__block {
				span {
					max-width: rem(90);
				}
			}
		}
	}

	.game__list__header {
		&__block {
			&__inner {
				&-btn {
					display: flex;
					flex-wrap: wrap;
					gap: rem(10.5);

					.btn {
						margin: rem(0);
					}
				}
			}
		}
	}

	.person__block__wrapper .form-group {
		margin: rem(0);
	}

	.person_btn__item .btn {
		padding: rem(5) rem(6);
	}

	.edit__person__select__switch .tariffsAdd__template {
		gap: rem(10);
	}

	.clubs {
		.club__content {
			flex-wrap: wrap;

			.btn-add-club {
				min-height: 100%;
				max-width: 100%;
			}

			.club__block {
				max-width: 100%;
			}
		}
	}

	.computers {
		.dialog__right__block {
			max-width: rem(403);
		}
	}

	.news {
		height: 100%;

		&__header {
			justify-content: inherit;

			&-right {
				left: 0;
			}

			&__left {
				justify-content: center;
			}
		}

		&__content {
			.achievements__item {
				flex-wrap: wrap;
			}
		}
	}

	//.achievements {
	//	&__header {
	//		&-left {
	//			justify-content: center;
	//		}
	//	}
    //
	//	&__item {
	//		&-btns {
	//			flex-wrap: wrap;
	//			width: 100%;
    //
	//			.btn {
	//				width: 100%;
	//			}
	//		}
    //
	//		&-delete {
	//			width: 100%;
	//		}
	//	}
	//}

	.edit__tournament {
		&__info {
			&__items {
				&__header {
					&__map {
						gap: rem(5);

						&__list {
							.line__right {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.tournament__edit {
		&-content {
			&-multi {
				max-width: 100%;
			}

			.field__line {
				display: none;
			}

			.form-group {
				margin-bottom: rem(0);
			}
		}
	}

	.dashboard {
		&__bg {
			&__users {
				h4 {
					font-size: rem(14);
				}
			}

			&__info {
				&__meta {
					span {
						white-space: nowrap;
					}
				}
			}

			p {
				font-size: rem(12);
			}
		}
	}
}

@media (max-width: 320px) {
	.person__items__block {
		padding: rem(20);

		&__elem {
			p {
				font-size: rem(13);
			}
		}
	}

	.tariffs-edit__btns-btn {
		padding: rem(0) rem(34);
		left: 4px;
	}

	.promo-code {
		&__item {
			&__list {
				&__info {
					flex-direction: column;
					width: 100%;
					gap: rem(10);

					&__inner {
						width: 100%;
						margin: rem(0);

						.btn {
							width: 100% !important;
						}
					}
				}
			}
		}
	}

	.reports {
		&__overview {
			&-content {
				overflow: auto;
			}
		}
	}

	.map-center {
		&__items {
			min-width: 100%;
		}

		&__console__selected {
			width: 100%;
		}

		&__console {
			&__selecter {
				width: 100%;
			}
		}
	}

	.discipline__content {
		&__form {
			gap: rem(15);
		}
	}

	.edit__tournament {
		&__info {
			&__items {
				&__header {
					&__map {
						gap: rem(10);
					}
				}
			}
		}
	}

	.promo-code {
		&__item {
			&__list {
				&__info {
					&__meta {
						&__text {
							p {
								font-size: rem(12);
							}
						}
					}
				}
			}
		}
	}

	.dashboard {
		.challenge-info__item {
			.tabs-list_content {
				li {
					font-size: rem(13);
				}
			}
		}

		&__bg {
			&__section {
				gap: rem(10);
			}

			p {
				font-size: rem(12);
			}
		}
	}

	.computers__header-left {
		overflow: hidden;
	}

	.news {
		&__header {
			&__left {
				flex-direction: column;
				gap: rem(11);

				.btn {
					margin: rem(0);
				}
			}
		}
	}

	.game__list__header {
		&__block {
			&__inner {
				.toggle__text {
					font-size: rem(12);
				}
			}
		}
	}

	//.achievements {
	//	&__header {
	//		&-left {
	//			flex-direction: column;
	//			gap: rem(11);
    //
	//			.btn {
	//				margin: rem(0);
	//			}
	//		}
	//	}
    //
	//	&__item {
	//		flex-direction: column;
    //
	//		&-desc {
	//			overflow: hidden;
	//		}
    //
	//		&-title {
	//			font-size: rem(13);
	//		}
	//	}
	//}

	.modal__block {
		padding: rem(20);
	}

	.computers__dialog__all-groups {
		.btn {
			margin-right: rem(0);
			white-space: normal;
		}
	}

	.settings {
		&__header {
			justify-content: center;
		}

		&__block {
			flex-direction: column;

			&-left {
				width: auto;
			}

			&-right {
				&-btn {
					width: auto;
				}
			}
		}
	}
}
