.logs {
    position: relative;
    padding: rem(30);
    color: #ffffff;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-search {
            width: rem(440);

            div {
                margin-bottom: rem(0);
            }
        }

        &-select {
            max-width: 45%;
			width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: rem(15);
        margin-top: rem(30);

        &-items {
            display: flex;
            flex-direction: column;
            gap: rem(10);
        }
    }

    &__item {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: rem(4) rem(4) rem(0) rem(0);
            padding: rem(10) rem(15);
            background: rgba(255, 255, 255, 0.10);

            &-left {
                display: flex;
                align-items: center;
                gap: rem(20);
            }

            &-date {
                gap: rem(30) !important;
            }
            &-desc {
                position: relative;
                display: flex;
                align-items: center;
                gap: rem(10);

                &:after {
                    content: '';
                    position: absolute;
                    height: rem(20);
                    width: rem(1);
                    margin-left: rem(10);
                    background: rgba(255, 255, 255, 0.10);
                    left: 100%;
                }
            }
        }

        &-content {
            padding: rem(10) rem(15);
            border-radius: rem(0) rem(0) rem(4) rem(4);
            background: var(--5, rgba(255, 255, 255, 0.05));
        }
    }

    &-title {
        font-size: rem(16);
    }


    &__carousel {
        width: 100%;
        //position: absolute;
        //left: 50%;
        //transform: translateX(-50%);
        //top: 100%;
		margin: rem(30) 0 0;
    }
}
