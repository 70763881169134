
.game__list__header {
    padding: rem(30);

    &__back {
        .btn {
            border: none;
            background-color: transparent;
            padding: 0;
            box-shadow: none;
            color: #3582F6;
        }
    }

    h3 {
        font-size: rem(20);
        line-height: rem(28);
        color: $white;
        font-weight: 400;
    }

    &__tariffs {
        margin-top: rem(30);
    }

    h5 {
        font-size: rem(14);
        line-height: rem(22);
        color: $white;
    }

    &__block {
        &__inner {
            &__title {
                display: flex;
                align-items: center;
                gap: rem(10);
                margin: rem(30) rem(0) rem(10) rem(0);
            }

            &-btn {
                display: flex;
                align-items: center;

                .btn {
                    max-width: rem(47);
                    width: 100%;
                    min-height: rem(36);
                    box-shadow: none;
                    border-color: #3582F6;
                }
                .btn:focus,
                .btn:hover,
                .btn:active {
                    background-color: rgba(53, 130, 246, 0.2);
                    color: #3582F6;
                }

                .active {
                    background-color: rgba(53, 130, 246, 0.2);
                    color: #3582F6;
                }
            }
            .bottom-block {
                max-width: rem(415);
                width: 100%;
                font-size: rem(12);
                line-height: rem(18);
                color: $white;
                margin-top: rem(10);
                opacity: 0.75;
            }
        }
    }

    .toggle__item {
        display: flex;
        align-items: center;
        gap: rem(14);
    }

    &__button {
		padding-top: rem(30);
		display: flex;
		align-items: center;

        .btn {
            width: 100%;
            justify-content: center;
            padding: rem(13) rem(20);
            border-radius: rem(4);
            box-shadow: none;
            border: none;
            min-height: rem(50);

			&-secondary {
				background: #303138;
			}
        }
    }

    .game-style {
        padding-top: rem(81);
    }
}
