.shift {
	&-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 0;
	}

	&-close {
		top: 2%;
		position: absolute;
		left: 93%;

	}

	&-title {
		color: #FFFFFF;
		font-size: rem(24);
		text-align: center;
		margin: 0 auto;
		margin-bottom: rem(10);
	}


	&__open {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: rem(20);

		&-project {
			display: flex;
			flex-direction: column;
			color: grey;
			border-radius: rem(4);
			padding: rem(10) rem(20);
			width: 100%;
			background: rgba(255, 255, 255, 0.05);
		}
	}

	&__preview {
		display: flex;
		width: 100%;
		flex-direction: column;
		flex-grow: 1;
		grid-gap: rem(8);
		gap: rem(8);

		&-project {
			display: flex;
			width: 100%;
			height: rem(72);
			flex-direction: column;
			padding: rem(10);
			background: rgba(255, 255, 255, 0.05);
			border-radius: rem(8);
			grid-gap: rem(4);
			gap: rem(4);
		}

		&-money {
			display: flex;
			min-height: rem(148);
			flex-grow: 1;
			grid-gap: rem(8);
			gap: rem(8);
		}

		&-proceeds {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: rem(10) rem(10) rem(25) rem(10);
			flex-grow: 1;
			background: rgba(255, 255, 255, 0.05);
			border-radius: rem(4);
		}

		&-cashbox {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: rem(10) rem(10) rem(25) rem(10);
			flex-basis: 194px;
			background: rgba(255, 255, 255, 0.05);
			border-radius: rem(4);
		}

		&-seperated {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-title {
			font-size: rem(15);
			color: grey;
		}

		&-paragraph24PX {
			line-height: rem(10);
			font-size: rem(24);
			color: white;
		}

		&-desc {
			display: flex;
			flex-direction: column;
			gap: rem(10);
		}

		&-paragraph14PX {
			line-height: rem(1);
			font-size: rem(14);
			color: white;
		}
	}

	&__button {
		margin-top: rem(20);
		display: flex;
		justify-content: center;
	}

	&__button2 {
		margin-top: rem(-10);
		display: flex;
		justify-content: center;
		margin-left: 0 !important;
		background: none !important;
		color: #2c87fd;
	}

	&__close {
		display: flex;
		flex-direction: column;

		&-title {
			font-size: rem(15);
			color: #FFFFFF;
			text-align: center;
			margin-bottom: rem(30);
		}

		&-password {
			border-radius: rem(4);
			height: rem(60);
			background: rgba(255, 255, 255, 0.05);
		}

		&-comment {
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.05);
		}
	}
}
