.notification {
    position: absolute;
    height: auto;
    width: rem(288);
    background-color: #222329;;
    margin-top: rem(80);
    right: 1%;
    z-index: 99999999;
    border-radius: rem(8);
    transition: height .9s ease;

    &.-expanded {
        display: block;
        transition: .9s all ease;
    }

    &-open {
        transition: display 0.9s ease-in-out;
    }

    &__container {
        position: fixed;
        inset: 0;
        background: rgba(0, 0, 0, 0.50);
    }

    &-block {
        position: relative;
        &__top {
            overflow-x: hidden;
            z-index: 99999;
            height: 200px;
            padding: 0 10px;
            transition: 0.5s all ease;

            &.-expanded {
                height: calc(100vh - 150px);
            }
        }

        &__item {
            padding: rem(5) rem(10);
            width: rem(280);
            margin-left: -10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: rem(1) solid #38393E;

            &-text {
                font-size: rem(12);
                color: #FFFFFF;
            }

            &-date {
                color: #ffffff;
                font-size: rem(10);
                opacity: .5;
            }
        }

        &__left {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

    }

    &-btn {
        display: flex;
        align-items: center;
        border-radius: 26px;
        background: linear-gradient(0deg, var(--blue-20, rgba(53, 130, 246, 0.20)) 0%, var(--blue-20, rgba(53, 130, 246, 0.20)) 100%), #222329;
        z-index: 99999;
        padding: rem(2) rem(15) ;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        &__expanded {
            display: flex;
            align-items: center;
            border-radius: 26px;
            background: linear-gradient(0deg, var(--blue-20, rgba(53, 130, 246, 0.20)) 0%, var(--blue-20, rgba(53, 130, 246, 0.20)) 100%), #222329;
            z-index: 99999;
            padding: rem(2) rem(15) ;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

	&__header {
		&-btn {
			max-width: rem(307);
			width: 100%;

			.btn {
				min-height: rem(50);
				justify-content: center;
			}

			.header__text {
				white-space: nowrap;
			}
		}
	}

	&__content {
		&-items {
			overflow: auto;
			background-color: rgba(255, 255, 255, 0.05);
			border-radius: rem(8);

			table {
				margin-bottom: 0;
			}
		}
	}
}

.ReactModal__Overlay {
    transition: .2s ease;
}
