.game {
    padding: rem(30);

    &__header {
        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            align-items: center;
            width: 100%;

            .btn {
                max-width: rem(184);
                width: 100%;
                justify-content: center;
                white-space: nowrap;
            }
        }

        &-right {
			width: 100%;
			max-width: rem(205);

            .btn {
                white-space: nowrap;
                width: 100%;
                justify-content: center;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: rem(30);

        &__pro {
            display: flex;
            flex-wrap: wrap;
            gap: rem(30);

            &__item {
                margin-top: rem(29);
				cursor: pointer;
				display: flex;
				flex-direction: column;
				max-width: rem(300);
				min-width: rem(300);

				img {
					max-width: rem(322);
					min-height: rem(195);
					max-height: rem(195);
					object-fit: contain;
				}

                &__hour {
                    margin-top: rem(15);

					p {
						margin-right: rem(15);
					}

                    &__timer {
                        display: flex;
                        align-items: center;
                        gap: rem(5);
                    }
                }

                &__delete {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    gap: rem(10);

                    .btn {
                        padding: rem(5) rem(10);
						font-size: rem(14);

						div {
							display: flex;
							align-items: center;
							pointer-events: none;
						}

						&-danger {
							margin-left: 0;
							max-height: rem(30);
						}
                    }
                }

                span {
                    font-size: rem(14);
                    line-height: rem(28);
                }

                p {
                    font-size: rem(20);
                    line-height: rem(28);
                    color: $white;
                }
            }
        }
    }
}

.tournament__edit {
    padding: rem(15);

    &__back {
        margin-bottom: rem(30);

		.btn {
			color: #3582F6;
		}
    }

    h2 {
        font-size: rem(20);
        line-height: rem(28);

        color: $white;
    }

    &-content {
        display: flex;
        gap: rem(10);
		flex-wrap: wrap;
		align-items: center;

        &__cover {
            margin-bottom: rem(20);
			width: 100%;
        }

		.form-group {
			width: 100%;
			margin-bottom: 0;
		}

        &__text {
			width: 100%;
            display: flex;
            align-content: center;
            gap: rem(10);
            //margin: rem(20) 0 rem(10) 0;
			margin-top: rem(20);

            p {
                font-size: rem(14);
                line-height: rem(22);
                color: $white;
            }
        }

		&-multi {
			margin-bottom: 0;
			max-width: calc((100% - (0.625rem * 2) - 15px) / 2);
			width: 100%;

            .form-control::placeholder {
                color: $white;
                font-size: rem(14);
                line-height: rem(22);
            }

            .react-datepicker__input-container {
                input::placeholder {
                    font-size: rem(14);
                    color: $white;
                    opacity: 0.5;
                }
            }
		}

        .form-group {
            .form-block {
                   input::placeholder {
                       color: $white !important;
                       font-size: rem(14) !important;
                   }
            }
        }

        &__field {
            display: flex;
            align-items: center;
            width: 100%;
            gap: rem(5);

            .form-group {
                width: 100%;
            }
        }

        &__calendar {
            display: flex;
            align-items: center;
            gap: rem(5);

            .custom-calendar {
                width: 100%;

            }
        }
        .custom-calendar .picker-wrapper.iconLeft input::placeholder {
            font-size: rem(14);
            line-height: rem(22);
            color: $white;
        }

        &__btn {
            margin-top: rem(20);
            width: 100%;

            &-button {
                width: 100%;
                justify-content: center;
                border-radius: rem(4);
                padding: rem(13) rem(20);
                border: none;
                color: rgba(255, 255, 255, 0.50);
            }
        }

        .css-1jqq78o-placeholder {
            font-size: rem(14);
        }

        .form-group {
            &-area {
                .form-block {
                    input::placeholder {
                        font-size: rem(14);
                        color: $white;
                        opacity: 0.5;
                    }


                }
            }
        }

        .form-group {
            .form-block {
                input::placeholder {
                    font-size: rem(14);
                    color: $white;
                    opacity: 0.5;
                }
            }
        }

    }

    &__inner {
        &__right {
            display: flex;
            align-items: center;
            gap: rem(10);
            margin-bottom: rem(29);

        }
    }

    .form-file {
        min-height: rem(236);
        max-width: rem(512);
        padding: 0;
        border-radius: rem(4);
        background: rgba(255, 255, 255, 0.05);

        svg {
            width: rem(53);
            height: rem(53);
        }

        span {
            opacity: 0.5;
            font-size: rem(20);
        }
    }
}

.field__line {
    width: 15px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: rem(10);
}

.edit__tournament {
    padding: rem(15);

    &__info {
        display: flex;
        flex-direction: column;
        padding-bottom: rem(60);

        &__items {
            display: flex;

            &__header {
                padding-left: rem(30);

                &__txt {
                    p {
                        font-size: rem(14);
                        line-height: rem(18);
                        color: $white;
                        opacity: 0.75;
                    }
                }

                &__meta {
                    display: flex;
                    align-items: center;
                    gap: rem(5);
                    padding-bottom: rem(20);
                }

				&__status {
					padding: rem(2) rem(8);
					border-radius: rem(4);
					font-size: rem(12);
					line-height: rem(20);
				}

                &__map {
                    display: flex;
                    align-items: center;
                    padding-bottom: rem(20);

                    &__list {
                        display: flex;
                        align-items: center;
                        gap: rem(5);

                        &__img {
                            display: flex;
                            padding: rem(1) rem(33);
                            border-radius: rem(4);
                            background: rgba(0, 0, 0, 0.30);
							max-width: rem(120);
							min-width: rem(120);
							max-height: rem(30);
							min-height: rem(30);
							width: 100%;

							img {
								object-fit: contain;
							}
                        }

                        p {
                            color: $white;
                            line-height: rem(28);
                        }
                    }
                    &__star {
                        display: flex;
                        align-items: center;

                        &__inner {
                            display: flex;
                            align-items: center;
							white-space: nowrap;
                            border-radius: rem(4);
                            background: #23334E;
							padding: rem(2) rem(8);
                        }

                        span {
                            color: #3582F6;
                            line-height: rem(20);
                            font-size: rem(12);
                            padding: rem(1) rem(8);
                        }
                    }
                }
            }

			&__left {
				max-width: rem(322);
			}
        }

        h5 {
            font-size: rem(20);
            line-height: rem(28);
            color: $white;
        }

        &__btn {
            padding-top: rem(60);
			display: flex;
			align-items: center;

			&-grid {
				max-width: rem(414);
				width: 100%;
			}

            .btn {
                justify-content: center;
				max-height: rem(50);
				min-height: rem(50);
				height: 100%;
				border: none;
            }
        }
    }

	&__team {
		h5 {
			font-size: rem(14);
		}
	}

    &__list {
        &__header {
            padding-bottom: rem(30);
        }

        &__team {
			padding: rem(29) rem(24);
			border-radius: rem(4);
			background: #292A2F;
			margin-bottom: rem(29);
            display: flex;
            width: 100%;
            flex-direction: column;

			h5 {
				color: $white;
			}

            &__info {
                padding-bottom: rem(20);
            }

            &__rating {
                display: flex;
                align-items: center;
                padding-bottom: rem(14);
            }

            &__item {
                display: flex;
                align-items: center;
                padding-bottom: rem(20);

                &__number {
                    span {
                        display: flex;
                        border-radius: rem(4);
                        padding: rem(1) rem(13);
                        border: 1px solid #70D584;
                        color: #70D584;
                        font-size: rem(12);
                        line-height: rem(20);
                    }
                }

            }

            &__roster {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                padding-top: rem(10);

                &__sport {
                    display: flex;
                    align-items: center;
                    gap: rem(15);

                    &__count {
                        display: flex;
                        border-radius: rem(4);
                        border: 1px solid #70D584;
                        padding: rem(1) rem(13);
                    }
                    img {
                        width: rem(31);
                        height: rem(30);
                    }

                    &-stars {
                        position: relative;
						border-radius: 50%;
						background-color: #939eb0;

						img {
							border-radius: 50%;
							min-width: rem(32);
							max-width: rem(32);
							min-height: rem(32);
							max-height: rem(32);
						}

                        svg {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }

					p {
						font-size: rem(14);
						max-width: rem(200);
						text-overflow: ellipsis;
						overflow: hidden;
					}
                }

                span {
                    max-width: rem(43);
                    min-height: rem(24);
                    display: flex;
					align-items: center;
                    justify-content: center;
                    width: 100%;
                    border: 1px solid;
                    border-radius: rem(4);
                    font-size: rem(12);
                    line-height: rem(20);
                }
            }

            &__button {
                display: flex;
                align-items: center;
                margin-top: auto;
                gap: rem(10);
                padding-top: rem(20);

				.btn {
					max-height: rem(50);
					min-height: rem(50);
					height: 100%;
				}

                .btn-danger {
                    border-radius: rem(4);
                    background: #FF5858;
                    padding: rem(13) rem(14);
					width: 100%;
                }

                .btn__tournament {
                    min-width: calc(100% - 50px - 10px);
                    width: 100%;
                    padding: rem(14) rem(0);
                    justify-content: center;
                }
            }
        }

        p {
            font-size: rem(14);
            color: $white;
            line-height: rem(22);
        }

        h2 {
            font-size: rem(20);
            color: $white;
            line-height: rem(28);
        }

        h4 {
            font-size: rem(14);
            color: $white;
            line-height: rem(22);
        }
    }
}

.line__right {
    height: 17px;
    width: 1px;
    background-color: $white;
    margin: rem(0) rem(14);
}

