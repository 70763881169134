.aside__block {
    background: rgba(255, 255, 255, 0.05);
    border-radius: rem(4);
    padding: rem(15);
    margin-bottom: rem(30);

    &__text {
        display: flex;
        align-items: center;
        column-gap: rem(5);
        margin-bottom: rem(6);
    }

    &__txt {
        font-size: rem(14);
        color: #FFFFFF;
        &.title {
            opacity: 0.5;
        }

    }

    &__sum {
        display: inline-block;
        padding: rem(6) rem(14);
        background-color: rgba(53, 130, 246, 0.20);
        border-radius: rem(4);
    }

    &__item {
        font-size: rem(15);
        color: #3582F6;
    }
}

.aside__check {
    margin-top: rem(30);

    &__comp {
        display: flex;
        column-gap: rem(10);
        margin-bottom: rem(10);
        align-items: center;
        opacity: 0.5;
    }

    &__list {
        display: flex;
        align-items: center;
        column-gap: rem(10);
        opacity: 0.5;
    }

    &__paragraph {
        font-size: rem(14);
        color: #FFFFFF;
    }
}


.aside__bonus {
    border-radius: rem(4);
    background: linear-gradient(90deg, rgba(70, 127, 255, 0.20) 0%, rgba(164, 20, 255, 0.20) 100%);
    padding: rem(15);
    margin-top: rem(30);

    &__piggy {
        display: flex;
        align-items: center;
        column-gap: rem(10);
        margin-top: rem(5);
    }


    &__safe {
        display: flex;
        align-items: center;
        column-gap: rem(10);
        margin-top: rem(5);
    }



    &.active {
        background: rgba(255, 255, 255, 0.05);
    }
}




