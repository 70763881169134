.computers {
	position: relative;
	padding: rem(30) rem(15);
	height: 100%;

	&__header, &__header-left, &__header-right {
		display: flex;
		align-items: center;
	}

	&__right-mobile {
		margin-top: rem(60);
		display: none;
	}

	&__mobile-meta {
		margin-top: rem(38);
		display: none;
	}

	&__header {
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: flex-start;

		&-left {
			max-width: 100%;
			overflow: auto;
			margin-right: rem(15);
			margin-bottom: rem(15);
		}

		.btn {
			white-space: nowrap;
			padding: rem(13) rem(30);

			& + & {
				margin-left: rem(10);
			}

			&.btn-link {
				color: #3582F6;
				border-color: #3582F6;

				&:hover, &:focus, &.active {
					background-color: rgba(53, 130, 246, 0.20);
				}
			}
		}

		&__mobile-meta {
			display: none;
		}
	}

	&__content {
		border-radius: rem(4);
		background: rgba(255, 255, 255, 0.05);
		margin-top: rem(30);

		&__table {
			overflow: auto;

			table {
				min-width: rem(1262);
			}

			&-header, &-header-btn {
				display: flex;
				align-items: center;
			}

			&-header {
				justify-content: space-between;
				padding: rem(30);

				h4 {
					margin-left: rem(5);
					color: $white;
					font-size: rem(16);
					line-height: rem(18);
                    min-width: rem(100);
				}

				&-color {
					display: block;
					border-radius: 50%;
					width: rem(7);
					min-width: rem(7);
					height: rem(7);
				}
			}

			&-under {
				&:hover {
					background: transparent !important;
				}

				&-block {
					display: flex;
					align-items: center;
					width: 100%;
				}
			}
		}
	}

	&-table {
		&-name-work {
			display: flex;
			align-items: center;

			p {
				font-size: rem(15);
				line-height: rem(22);
				text-transform: uppercase;
				margin-right: rem(10);
			}

			.btn {
				cursor: default !important;
				min-height: auto !important;
				font-size: rem(12);
				line-height: rem(20);
				max-height: rem(24);

				&.btn-link {
					color: #3582F6;
					border-color: #3582F6;
					padding: rem(2) rem(8);
					max-width: rem(79);
				}

				&.no-user {
					color: $white;
					border-color: transparent;
				}
			}

			.btn + .btn {
				margin-left: rem(10);
			}
		}
	}

	&__dialog__all-groups {
		flex-wrap: wrap;
		justify-content: left;

		.btn {
			width: auto;
			margin-bottom: rem(15);
			margin-left: 0;
			margin-right: rem(15);
		}
	}

	&__dialog {
		&__right {
			&__list {
				&__block {
					background-color: rgba(255, 255, 255, 0.05);
					padding: rem(16);
					border-radius: rem(4);
					margin-bottom: rem(10);

					&__login {
						border-bottom: rem(1) solid rgba(255, 255, 255, 0.10);
						margin-bottom: rem(10);
						padding-bottom: rem(10);
					}

					&__header {
						display: flex;
						justify-content: space-between;
						padding-bottom: rem(15);

						&-type {
							padding-top: rem(10);
							display: flex;
							align-items: center;
							gap: rem(10);

							span {
								padding: 0 rem(10);
								border-radius: rem(4);

								&.green {
									background-color: rgba(112, 213, 132, 0.2);
									color: #70D584;
								}

								&.blue {
									background-color: rgba(53, 130, 246, 0.2);
									color: #3582F6;
								}
							}
						}

						&-left {
							.title {
								opacity: 1;
							}

							p {
								text-align: left;
							}
						}

						&-right {
							.btn {
								&:hover {
									path {
										stroke: #ac0000;
									}
								}
							}
						}

						&-other {
							padding-top: rem(15);
							border-top: rem(1) solid rgba(255, 255, 255, 0.1);

							&-text {
								display: flex;
								align-items: center;
								gap: rem(10);
							}
						}
					}

					&__price {
						p {
							text-align: right;
						}
					}
				}
			}
		}
	}

	.dialog__right__container {
		margin-right: 0;
	}
}

.create__group {
	.btn {
		margin-top: rem(30);
	}
}

.modal__block {
	.edit__stocks__right-content {
		margin-bottom: rem(30);

		.form-file {
			max-width: rem(142);
			min-height: rem(142);
		}
	}
}
