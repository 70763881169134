.calendar {
	color: $white;
	position: relative;
	height: 100%;

	&__scroll {
		&-items {
			position: relative;
			min-width: rem(1690);
			height: 100%;
			overflow: auto;
		}

		&-btn {
			position: absolute;
			width: 100%;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: calc(1.625rem + 20px + 15px);
			z-index: 3;
			cursor: grab;
		}
	}

	&-time {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: rem(26);
		//min-width: rem(1690);

		&-items {
			position: relative;
		}

		&-block, &-block-half{
			position: relative;
			padding: rem(10) 0;
			font-size: rem(12);
			font-weight: 600;
		}

		&-block {
			&:before {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 12px;
				background-color: $white;
				margin: 4px 0;
			}

			&-half {
				height: 32px;
				&:before {
					content: '';
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateY(-50%);
					width: 1px;
					height: 6px;
					background-color: $white;
					margin: 7px 0;
				}
			}
		}

		&-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			z-index: 1;
			border: none;
			background-color: #1E1F24;
			padding-left: rem(15);

			&.left {
				transform: rotate(180deg);
			}

			&.right {
				margin-left: auto;
			}
		}
	}

	&-container {
		position: relative;
		margin: 0 rem(15.5);

		&:hover {
			.calendar-block-add {
				opacity: 1;
			}
		}
	}

	&-items {
		position: relative;
		z-index: 1;
	}

	&-item {
		border-top: 1px solid rgba(255, 255, 255, 0.05);
		padding: rem(10) 0;

		&-title {
			font-size: rem(12);
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			opacity: 0.5;
			min-height: rem(26);
            position: relative;

			p {
				position: absolute;
                left: 0;
                top: 0;
                transition: all 0.2s ease-in-out;
			}
		}
	}

	&-blocks {
		position: relative;
		min-height: rem(81);
		max-height: rem(81);
		margin-bottom: rem(5);
	}

	&-block {
		position: absolute;
		padding: rem(10);
		border-radius: rem(4);
		display: inline-block;
		color: #FFFFFF;
		height: 100%;
		z-index: 2;
		cursor: pointer;

		&.disabled {
			opacity: 0.5;
		}

		p {
			font-size: rem(12);
			font-weight: 400;
			line-height: rem(16);
		}

		&-user {
			border-radius: 50%;
			max-width: rem(40);
			max-height: rem(40);
			min-width: rem(40);
			min-height: rem(40);
			width: 100%;
			height: 100%;
		}

		&-users {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			padding-bottom: rem(5);
		}

		&-right {
			display: flex;
			align-items: center;

			p {
				margin-left: rem(4);
			}
		}

		&-add {
			font-size: rem(30);
			color: #3582F6;
		}

		&-title {
			display: flex;
			align-items: center;
			gap: 5px;
            overflow: hidden;
            text-overflow: ellipsis;

            p {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 68%;
            }
		}
	}

	&-current-time {
		position: absolute;
		top: rem(36);
		height: calc(100% - 4rem);
		width: 1px;
		border-radius: 100px;
		background: linear-gradient(180deg, #FFC700 0%, rgba(255, 199, 0, 0.00) 100%);
		margin-left: rem(15.5);
		z-index: 3;

		&-fon {
			content: '';
			position: absolute;
			right: 0;
			width: 258px;
			border-radius: 0;
			z-index: 0;
			background: linear-gradient(270deg, rgba(255, 199, 0, 0.03) 0%, rgba(255, 199, 0, 0.00) 100%);
		}

		&:hover {
			.calendar-block-add {
				opacity: 1;
			}
		}
	}

	&-block-add {
		position: absolute;
		top: 0;
		width: 73px;
		height: rem(81);
		transition: opacity 0.3s, visibility 0.3s;
		cursor: pointer;
		border-radius: 8px;
		background: rgba(53, 130, 246, 0.03);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		z-index: 1;

		&:hover {
			opacity: 1;
		}
	}
}
