.tournaments {
	&__grid {
		&__tour {
			padding-right: rem(30);

			&__block {
				overflow-x: auto;

				&__team {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					width: 100%;
					height: 100%;
					padding: rem(7) 0;

					&-first {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $white;
						border-radius: rem(8) rem(8) 0 0;
						font-size: rem(14);
						line-height: rem(22);
						padding: 0;
						cursor: pointer;

						* {
							pointer-events: none;
						}
					}

					&-two {
						border-radius: 0 0 rem(8) rem(8);
					}
				}

				[role="navigation"] {
					display: none;
				}
			}

			&__popup {
				padding: rem(30);
				background-color: #1E1F24;
				border-radius: 4px;
				min-width: rem(250);

				h2 {
					font-size: rem(20);
					font-weight: 400;
				}

				&__team {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: rem(5) 0;

					.btn {
						margin-left: rem(15);

						* {
							pointer-events: none;
						}
					}
				}

				&__judge {
					display: flex;
					align-items: center;

					.btn {
						margin-left: 0;
						border: 0;
						width: 100%;
						text-align: center;
						justify-content: center;
						max-height: rem(50);
						min-height: rem(50);
					}

					.btn + .btn {
						margin-left: rem(15);
					}
				}
			}

			&__container {
				display: flex;
				align-items: center;

				//div:first-child {
				//	z-index: 1;
				//}

				&__winner {
					margin-top: rem(50);
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $white;
					border-radius: rem(8);
					font-size: rem(14);
					line-height: rem(22);
					padding: 0;
					max-width: rem(175);
					min-width: rem(175);
					width: 100%;
					min-height: rem(33);
					background: rgba(249, 212, 72, 0.20);
					position: relative;

					* {
						pointer-events: none;
					}

					&:before {
						content: '';
						width: 100%;
						height: rem(1);
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 100%;
						background-color: rgba(255, 255, 255, 0.05);
						z-index: 0;
					}
				}
			}
		}

		&__table {
			padding: rem(15);

			.table__content div {
				width: 100%;
				//display: table-row;
			}

			&-header {
				p {
					font-size: rem(14);
					opacity: 0.8;
				}
			}

			&-footer {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

@media screen and (max-width: 465px) {
	body {
		.game {
			padding: rem(40) rem(10) rem(80) rem(10);
			&__content {
				gap: rem(0);

				&__pro {
					padding: rem(10);

					&__item {
						border-radius: 8px;
						padding: rem(10);
						background: rgba(255, 255, 255, 0.05);

						img {
							background: black;
							min-height: rem(150);
						}

						&__hour {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							height: 100%;

							&__timer {
								flex-direction: column;
								align-items: normal;

								span {
									display: flex;
									align-items: center;
									gap: rem(5);
								}
							}
						}

						&__delete {
							width: 100%;

							.btn {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 50%;
								height: 40px;
								max-height: none;
							}
						}
					}
				}
			}
		}

		.tournament {
			&__edit{
				&-content {
					&__cover {
						margin-bottom: 0;

						h4 {
							margin-bottom: 0;
						}
					}
					&__text {
						margin-bottom: 0;
					}
				}

				&__back {
					width: 100%;
					align-items: center;
					display: flex;

					h2 {
						width: 100%;
						text-align: center;
					}
				}
				&__inner {
					&__right {
						margin-bottom: 0;
						justify-content: normal;
					}

					&__mobile {
						&__text {

							font-size: rem(14);
							color: #FFFFFF;
							margin-bottom: rem(10);
						}
					}
				}
			}
		}
	}
}

