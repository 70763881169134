.achievements {
	position: relative;
	padding: 1.875rem 0.9375rem;
	height: 100%;
	color: #FFFFFF;

	&__header{
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-left {
			display: flex;
			align-items: center;
		}

		&-nonActive {
			background: none !important;
			border: rem(1) solid #3582F6 !important;
			color: #3582F6;
		}

		&-active {
			color: #3582F6;
			background: var(--blue-20, rgba(53, 130, 246, 0.20)) !important;
			border: rem(1) solid #3582F6 !important;
			&:hover {
				color: #3582F6 !important;
			}
		}
	}

	&__item {
		margin-top: rem(40);
		display: flex;
		//align-items: center;
		gap: rem(20);
		//flex-wrap: wrap;
		padding-bottom: rem(20);

		&s {
			min-width: rem(400);
			max-width: none;
			width: 100%;
		}

		&-header {
			display: flex;
			align-items: center;
			gap: rem(20);
		}

		&-point {
			display: flex;
			align-items: center;
			gap: rem(5);
			padding: rem(5) rem(10);
			border-radius: rem(4);
			background: var(--20, rgba(249, 212, 72, 0.20));
			color: #F9D448;
		}

		&-title {
			font-size: rem(16);
		}

		&-desc {
			margin-top: rem(10);
			font-size: rem(14);
			line-height: rem(18);
			font-weight: 400;
			opacity: 0.75;

			a {
				color: white;
				padding-left: rem(10);
			}
		}

		&-content {
			display: flex;
			flex-direction: column;
		}

		&-btns {
			margin-top: rem(30);
			display: flex;
			gap: rem(15);
		}

		&-img {
			margin-top: rem(10);
			min-height: rem(216);
			max-height: rem(216);
			max-width: rem(216);
			height: 100%;
			background: #222329;
			width: 100%;
			object-fit: contain;
		}

		&-delete {
			cursor: pointer;
			border-radius: rem(4);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: rem(13) rem(20);
			background-color: #B4474E;
		}
	}

	&__content {
		.line {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

@for $i from 1 through 15 {
	@media (max-width: 1920px - ($i * 50)) {
		.achievements {
			&__items {
				min-width: #{400 - ($i * 10)}px;
				max-width: none;
				width: 100%;
			}
		}
	}
}

@for $i from 1 through 7 {
	@media (max-width: 1100px - ($i * 50)) {
		.achievements {
			&__items {
				min-width: #{360 - ($i * 10)}px;
				max-width: none;
				width: 100%;
			}
		}
	}
}

@for $i from 1 through 10 {
	@media (max-width: (810 - ( $i * 50))px) {
		.achievements {
			&__items {
				min-width: #{300 - ($i * 10)}px;
				max-width: none;
				width: 100%;
			}
		}
	}
}

//@media (max-width: 1650px) {
//	.achievements {
//		&__items {
//			min-width: rem(350);
//			max-width: none;
//			width: 100%;
//		}
//	}
//}
//
//@media (max-width: 1440px) {
//	.achievements {
//		&__items {
//			min-width: rem(350);
//			max-width: none;
//			width: 100%;
//		}
//	}
//}

@media screen and (max-width: 465px) {
	body {
		.edit{
			&__news {
				&__header {
					&__item {
						width: 100%;
					}
				}
			}
		}
		.achievements {
			&__header {
				&-left {
					overflow-y: scroll;
					text-wrap: nowrap;
					flex-wrap: unset;
					display: flex;
					flex-direction: row;
				}
			}
			&__content {
				width: 100% ;
				display: flex;
				flex-wrap: wrap;
			}

			&__items {
				width: 50% !important;
				min-width: unset;
				max-width: unset;
			}

			&__item {
				min-height: rem(300);
				padding: rem(10);
				border-radius: 8px;
				background:  rgba(255, 255, 255, 0.05);

				&-title {
					min-height: rem(50);
				}

				&-img {
					display: flex;
					align-items: center;
					justify-content: center;
					height: rem(120) ;
					position: relative;
				}

				&-subtitle {
					font-size: rem(12);
					opacity: .5;
					font-weight: 400;
				}

				&-point {
					left: 100%;
					transform: translate(-100%, -100%);
					top: 100%;
					position: absolute;
				}

				&-header {
					align-items: normal;
					min-height: rem(50);
					margin-top: rem(30);
				}

				&-btns {
					flex-direction: row !important;
					display: flex;
					gap: rem(10);

					.btn {
						width: 50%;
						div {
							width: 100%;
						}
					}

					div {
						width: 50%;
					}
				}
			}

			&__edit {
				&-title {
					margin-bottom: 0;
				}

				&-header {
					width: 100%;
					display: flex;
					flex-direction: row;
					
					h2 {
						width: 100%;
						text-align: center;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}


