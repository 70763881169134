body {
    .ck {
        //&-focused {
        //    display: none;
        //}

        &-blurred {
            border: none !important;
        }
        &-toolbar{
            border-width: 0 !important;
            color: #FFFFFF !Important;
            &__items {
                color: #FFFFFF !Important;
            }
            &__separator {
                background: rgba(255, 255, 255, 0.5) !important;
            }
        }
        &-editor{
            &__top {
                border-width: 0 !important;


                color:  #ffffff !important;
            }
        }
        &-reset{
            &_all {
                color:  #ffffff !important;
            }
        }

		ul {
			padding-left: rem(20);
		}
    }
}

.ck-reset_all :not(.ck-reset_all-excluded ) {
    color: #ffffff !important;
}

.ck-reset_all :not(.ck-reset_all-excluded) {
    background: #2a2b2f !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: none !important;
}



.ck.ck-balloon-panel.ck-powered-by-balloon {
    display: none !important;
    margin-left: 100px !important;
    --ck-powered-by-background: var(--accented-background-color) !important;
    color: red !important;
}

.ck.ck-button.ck-on {
    background: rgba(255, 255, 255, .1) !important;
}

//.ck.ck-icon.ck-reset_all-excluded.ck-icon_inherit-color.ck-button__icon {
//    display: none !important;
//}