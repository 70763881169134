
.discounts__item {
    padding: rem(30);

    &__button {
        display: flex;
        justify-content: flex-end;
		padding-bottom: rem(15);

        .btn {
            max-width: rem(193);
            width: 100%;
            justify-content: center;
        }
    }

    &__block {
        display: flex;
        align-items: center;
        gap: rem(20);
		padding: rem(15) 0;

        &__meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: rem(4);
            background: rgba(255, 255, 255, 0.05);
            padding-left: rem(20);

            span {
                max-width: rem(127);
                width: 100%;
                border-radius: 30px 4px 4px 10px;
                display: flex;
                justify-content: center;
                padding: rem(14);
                background: linear-gradient(90deg, #4A80EE 0%, #1F48A5 100.93%);

            }
        }

        p {
            color: #FFFFFF;
            line-height: rem(22);
        }

        span {
            font-size: rem(20);
            color: #FFFFFF;
            line-height: rem(22);
        }

        &__inner {
            .btn {
                border-radius: rem(4);
                background: rgba(53, 130, 246, 0.15);
                min-width: rem(46);
                min-height: rem(46);
                justify-content: center;
            }
        }

    }
}

.create__group__discounts {
    &__info {
        display: flex;
        flex-direction: column;
        gap: rem(15);

        &__modal {
            label {
                color: $white;
            }

            .toggle__item {
                display: flex;
                align-items: center;
                gap: rem(14);
            }
        }
    }

    p {
        font-size: rem(14);
        //color: $white;
        line-height: rem(22);
    }

    span {
        font-size: rem(12);
        //color: $white;
        line-height: rem(22);
    }

    .btn {
        width: 100%;
        justify-content: center;
        padding: rem(13) rem(20);
        border-radius: rem(4);
        //background: #303138;
        margin-top: rem(30);
		font-size: rem(16);
        border: none;

		&-secondary {
			color: rgba(255, 255, 255, 0.50);
		}
    }
}
