.person {
    &__block {
        padding: rem(30) rem(20);

        .person_btn__item {
            max-width: rem(342);
            width: 100%;
            padding: rem(0) rem(10);

            .btn {
                min-height: rem(50);
            }
        }

        .row {
            margin-left: rem(-10);
            margin-right: rem(-10);
        }

        .col {
            &-md-4, &-md-6, &-12 {
                padding-left: rem(10);
                padding-right: rem(10);
            }
        }

        .container-fluid {
            padding-left: rem(10);
            padding-right: rem(10);
        }

        &__wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        &-form {
            width: 100%;
            max-width: rem(342);
            padding: rem(0) rem(10);
        }

    }

    &_btn__item button {
        width: 100%;
        justify-content: center;
    }

    &__items {
        &__img {
            img {
                max-width: rem(44);
                border-radius: 50%;
            }
        }

        &__block {
            padding: rem(30);
            display: flex;
            flex-direction: column;
            border-radius: rem(4);
            background: (rgba(255, 255, 255, 0.05));
            margin: rem(10) 0;

            p {
                font-size: rem(14);
                line-height: rem(22);
            }

            &__item {
                display: flex;
                align-items: center;
                margin-bottom: rem(15);
                gap: rem(10);
            }

            &__name {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: rem(5);

                span {
                    font-size: rem(12);
                    color: #3582F6;
                }
            }

            &__manager {
                border-radius: rem(4);
                background: (rgba(53, 130, 246, 0.20));
                display: flex;
                padding: rem(2) rem(8);
                color: #3582F6;
            }

            &__elem {
                display: flex;
                gap: rem(10);
                align-items: center;
                color: #FFFFFF;

                .btn {
                    background: none;
                    border: none;
                    padding: 0;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: rem(5);
                align-items: flex-start;
                color: #FFFFFF;
                margin-top: rem(15);

                span {
                    font-size: rem(12);
                    line-height: rem(20);
                    opacity: 0.5;
                }
            }

            &__section {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &__fix {
                padding-left: 0 !important;

                &__data {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3125rem;
                    align-items: flex-start;
                    color: #FFFFFF;
                    margin-top: 0.9375rem;

                    span {
                        font-size: rem(12);
                        color: #FFFFFF;
                        opacity: 0.5;
                    }

                    &__work {
                        padding: rem(2) rem(8);
                        border-radius: rem(4);

                        p {
                            font-size: rem(12);
                            line-height: rem(20);
                        }
                    }
                }
            }

            &__boots {
                padding-right: 0 !important;
            }

        }
    }

    &__popup {
        padding: rem(10) 0;

        &-block {
            width: rem(230);
            position: absolute;
            border-radius: rem(8);
            background: #2D2E31;
            z-index: 9999;
            margin-top: rem(5);
            margin-left: rem(-100);
            padding: rem(12);
            border: rem(1) solid gray;
        }

        &-title {
            font-size: rem(14) !important;
        }

        &-desc {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            //border-bottom: 1px solid grey;
            padding-bottom: rem(15);

            p {
                line-height: rem(16);
                font-size: rem(12) !important;
                color: $white;
            }
        }

        &-link {
            font-size: rem(12) !important;
            margin-top: rem(10);

            a {
                color: #2c87fd;
            }
        }
    }
}


