.users {
	&-table-center {
		display: flex;
		align-items: center;
		gap: rem(10);
		min-height: rem(52);

		.icon {
			border-radius: 50%;
			overflow: hidden;
			min-width: rem(32);
			max-width: rem(32);
			max-height: rem(32);
			min-height: rem(32);

			img, svg {
				min-width: rem(32);
				max-width: rem(32);
				max-height: rem(32);
				min-height: rem(32);
			}
		}
	}

	&__content {
		border-radius: rem(8);

		&__table {
			.table {
				min-width: rem(1028);

				&__content {
					td {
						padding: rem(10) rem(14);
						vertical-align: middle;
					}
				}
			}
		}
	}

	&__edit {
		padding-left: rem(15);
		padding-top: rem(30);
		padding-bottom: rem(30);
	}

	&__user {
		&-back {
			padding-bottom: rem(15);
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: $white;

			&-photo {
				display: flex;
				align-items: center;
				gap: rem(20);

				&-img {
					border-radius: 50%;
					overflow: hidden;
				}

				img, svg {
					min-width: rem(50);
					max-width: rem(50);
					max-height: rem(50);
					min-height: rem(50);
				}

				p {
					font-size: rem(20);
					line-height: rem(28);
				}
			}

			&-btn {
				.btn {
					max-width: rem(204);
					width: 100%;
				}

				.btn + .btn {
					border: none;
					background: none;
					outline: none;
					box-shadow: none;
					display: none;
				}
			}
		}

		&-content {
			color: $white;

			&__block {
				padding-top: rem(20);

				h4 {
					font-size: rem(20);
					line-height: rem(28);
					margin-bottom: rem(20);
				}

				.form__group {
					margin-bottom: rem(10);

					label {
						font-size: rem(14);
						line-height: rem(20);
						opacity: 0.5;
						margin-bottom: rem(4);
					}

					.form-group {
						margin-bottom: 0;
					}
				}
			}

			&-game {
				display: flex;
				justify-content: space-between;

				.btn {
					padding: rem(8) rem(39);
					background-color: rgba(0, 0, 0, 0.20);

					&-primary {
						max-width: rem(205);
						min-width: rem(205);
						min-height: rem(50);
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						&-primary {
							margin-left: auto;
						}
					}
				}

				&__block {
					border-radius: rem(4);
					display: flex;
					align-items: center;
					justify-content: center;


					& + & {
						margin-left: rem(15);
					}
				}
			}

			&-center {
				&-header, &-right {
					display: flex;
					align-items: center;
				}

				&-header {
					justify-content: space-between;
				}

				&-right {
					gap: rem(10);

					p {
						font-size: rem(14);
						line-height: rem(22);
					}
				}

			}

			&-progress {
				&__items {
					display: flex;
					align-items: center;
				}

				&__item {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: rem(4);
					background: rgba(255, 255, 255, 0.05);
					margin-right: rem(15);
					margin-bottom: rem(15);
				}
			}
		}

		&-footer {
			display: flex;
			align-items: center;
			padding-top: rem(30);

			&-btn {
				display: flex;
				align-items: center;
				width: 100%;

				.btn {
					border-color: #3582F6;
					color: #3582F6;
				}
			}

			.btn {
				max-width: rem(205);
				min-height: rem(50);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				&-primary {
					margin-left: auto;
				}
			}

			.btn-danger {
				max-width: rem(305);
				border: none;
			}
		}
	}
}

.users__user-back {
	.btn {
		background: none;
		box-shadow: none;
		border: none;

		.header__text {
			display: none;
		}
	}
}
