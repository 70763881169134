.form {
	&-group {
		display: flex;
		border-radius: rem(4);
		background: rgba(255, 255, 255, 0.05);
		align-items: center;
		justify-content: space-between;
		position: relative;

		&-area {
			.form-control {
				padding-bottom: rem(40);
				height: auto;
			}
		}

		&.searchable {
			.filter-select {
				margin-bottom: 0;

				.react-select-prefix__control {
					cursor: text;
				}
			}
		}

		.filter-select {
			margin-bottom: 0;

			input {
				color: $white !important;
			}
		}
	}

	&-block {
		width: 100%;
	}

	&-label, &-control {
		font-size: rem(14);
		line-height: rem(22);
	}

	&-label {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: rem(14);
		color: rgba(255, 255, 255, 0.50);
		margin-bottom: 0;
		@include transition();
		cursor: pointer;

		&.focus {
			top: rem(4);
			transform: none;
		}

		&.left {
			padding-left: rem(32);
		}

		&.right {
			padding-right: rem(32);
		}

		&.left.right {
			padding: 0 rem(32);
		}
	}

	&-control {
		width: 100%;
		outline: none;
		background-color: transparent !important;
		color: $white;
		border: none;
		min-height: rem(50);
		padding: rem(14);

		&.label {
			padding: rem(24) rem(14) rem(4);

			&.left {
				padding: rem(24) rem(14) rem(4) rem(28 + 20);
			}

			&.right {
				padding: rem(24) rem(28 + 20) rem(4) rem(14);
			}

			&.left.right {
				padding: rem(14) rem(28 + 20) rem(14) rem(28 + 20);
			}
		}

		&.left {
			padding: rem(14) rem(14) rem(14) rem(28 + 20);
		}

		&.right {
			padding: rem(14) rem(28 + 20) rem(14) rem(14);
		}

		&.left.right {
			padding: rem(14) rem(28 + 20) rem(14) rem(28 + 20);
		}

		&::placeholder {
			color: rgba($color: $white, $alpha: 0.5);
		}
		&:hover,
		&:focus {
			color: $white;
		}

		&:disabled,
		&:hover,
		&:focus{
			background: rgba(255, 255, 255, 0.05);
		}

		&:disabled{
			cursor: no-drop;
		}
	}

	&-textarea {
		background: rgba(255, 255, 255, 0.05);
	}

	&-file {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: rem(10);
		color: $white;
		border-radius: rem(4);
		overflow: hidden;

		label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}

		img {
			margin-bottom: rem(10);
		}

		span {
			display: block;
			margin-top: rem(10);
			text-align: center;
		}
	}

	&-icon {
		&-left, &-right {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&-left {
			left: rem(14);
		}

		&-right {
			right: rem(14);

			p {
				font-size: rem(12);
				line-height: rem(22);
				opacity: 0.5;
				color: $white;
			}
		}
	}

	&-color {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 99;
	}
}

//.form-control {
//	border-radius: rem(4);
//	background: rgba(255, 255, 255, 0.05);
//	color: $white;
//	border: 0;
//
//	&::placeholder {
//		color: rgba($color: $white, $alpha: 0.2);
//	}
//	&:hover,
//	&:focus {
//		color: $white;
//	}
//
//	&:disabled,
//	&:hover,
//	&:focus{
//		background: rgba(255, 255, 255, 0.05);
//	}
//}

.custom-calendar {
	display: flex;
	border-radius: rem(4);
	background: rgba(255, 255, 255, 0.05);
	align-items: center;
	justify-content: space-between;
	position: relative;

	& + & {
		margin-left: rem(15);
	}

	.delimetr {
		height: rem(24);
		width: rem(1);
		background-color: rgba(0, 0, 0, 0.1);
		position: relative;
	}

	.icon-wrapper {
		position: absolute;
		left: rem(14);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.picker-wrapper {
		width: 100%;
		//display: flex;


		&.iconLeft {
			input {
				padding: rem(14) rem(14) rem(14) rem(28 + 20);
			}
		}

		.react-datepicker {
			background-color: #292a2f;
			color: $white;
			border: rem(1) solid #1e1f24;

			&-wrapper {
				width: 100%;
			}

			&__header {
				background-color: #292a2f;
				color: $white;
				border-bottom: rem(1) solid #1e1f24;
			}

			&__today-button, &__time-list-item {
				background-color: #292a2f;
				color: $white;
			}

			&__day-name, &__day, &__time-name {
				color: $white;

				&:hover {
					background-color: #1e1f24;
				}
			}

			&__navigation {
				&-icon {
					&:before {
						border-width: 2px 2px 0 0;
					}
				}

				span {
					text-indent: -9999px;
					white-space: nowrap;
					font-size: 10px;
					color: rgba(0, 0, 0, 0);
				}
			}

			&__month-dropdown {
				width: auto;
				padding: 0 rem(20);
				left: 0;

				&-container {
					position: relative;
				}
			}

			&__year-read-view--down-arrow,
			&__month-read-view--down-arrow,
			&__month-year-read-view--down-arrow {
				top: 5px;
				border-width: 2px 2px 0 0;
			}

			&__time-container {
				.react-datepicker__time {
					background-color: #1e1f24;

					.react-datepicker__time-box {
						ul.react-datepicker__time-list {
							li.react-datepicker__time-list-item {
								&:hover {
									background-color: #1e1f24;
								}
							}
						}
					}
				}
			}

			&__current-month, &-time__header {
				color: $white;
			}

			&__day {
				&--selected {
					background-color: #3582F6;
				}
			}

			&__year-option,
			&__month-option,
			&__month-year-option {
				&:hover {
					background-color: #3582F6;
				}
			}

			&__year-dropdown,
			&__month-dropdown,
			&__month-year-dropdown {
				background-color: #1e1f24;
			}

			&__current-month,
			&__current-month--hasYearDropdown,
			&__current-month--hasMonthDropdown {
				display: none;
			}

			//&-popper[data-placement^=bottom] {
			//	.react-datepicker__triangle {
			//		left: 50% !important;
			//		transform: translate(50%, 0) !important;
			//	}
			//}

			&__day--keyboard-selected,
			&__month-text--keyboard-selected,
			&__quarter-text--keyboard-selected,
			&__year-text--keyboard-selected {
				background-color: #3582F6;
			}

			&__day--disabled,
			&__month-text--disabled,
			&__quarter-text--disabled,
			&__year-text--disabled {
				opacity: 0.5;
			}
		}

		span {
			font-size: rem(14);
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: rem(6);
		}

		input {
			width: 100%;
			outline: none;
			font-size: rem(14);
			background-color: transparent;
			line-height: rem(22);
			color: $white;
			border: none;
			min-height: rem(50);
			padding: rem(14);
		}
	}
}

:root {
	--ck-z-default: 5 !important
}
